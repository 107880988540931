import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Home } from './Pages/home';
import { Provider } from 'react-redux';
import store from './Redux';
import { Signup } from './Registrations/signup';
import { Login } from './Registrations/login';
import { DPLogin } from './Pages/delivery_partner_login';
import { DPHome } from './Pages/dp-home';
import { DeliveryEarnings } from './Components/delivery_earnings';
import { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';


function App() {
  const theme = createTheme({
    palette: {
      background: { "default": "#F5F5F5", "light": "#FFFFFF","upload":"#FAFAFA" },
      primary: { "button": "#ED1E79", "main": "#ED1E79" },
      secondary: { "main": "#6B7280", "border": "#D9D9D9" },
      error: { "main": "rgba(245, 39, 24, 1)" },
      text: { "primary": "#333333", "secondary": "#6B7280","login":"#000000D9" }
    },
    typography: { "fontFamily": 'Poppins', }
  })

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path='/dashboard' element={ <Home /> } />
            <Route path="/signup" element={<Signup />} />
            <Route path='/' element={<Login />} />
            <Route path='/shino-partner' element={<DPLogin />} />
            <Route path='/dp-home' element={<DPHome />} />
            <Route path='/dp-earnings' element={<DeliveryEarnings />} />
            {/* <Route path='dashboard' element={<Dashboard/>} /> */}

          </Routes>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  );
}

export default App;

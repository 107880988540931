import { Box, Button, InputBase, Typography } from "@mui/material";
import hero from "../Assets/view-animated-cartoon-character-shopping-goods 1.svg"
import { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { updateUsername } from "../Redux/delivery-partner";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import CustomAxiosDP from "../utils/CustomAxiosDP";

export function DPLogin() {
    const toast = useRef(null);
    const [registration, setRegistration] = useState('')
    const [formData, setFormData] = useState({
        mobileno: '',
    });
    const mobileno = useSelector(state => state.user.mobileno)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        console.log("here")
        e.preventDefault();
        try{
            const response = await CustomAxiosDP.post(`login/`, formData).then(res => {
            const data = res.data
            console.log(data.message);
            if (data.message == "Successfully Login") {
                console.log(data)
                dispatch(updateUsername(data.user.username))
                localStorage.setItem('authtoken', data.access_token);
                localStorage.setItem('refreshToken', data.refresh_token);
                navigate("/dp-home")
                }
            })
        }
        catch (e) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Invalid Credentials', life: 3000 });
        }

    };
    // if(data.user.role == "admin") {
                    
    //     localStorage.setItem('role', data.user.role);
    //     localStorage.setItem('id', data.user.id);
    //     localStorage.setItem('first_name', data.user.first_name);
    //     localStorage.setItem('last_name', data.user.last_name);
    //     dispatch(updateFirst_name(data.user.first_name))
    //     dispatch(updateLast_name(data.user.last_name));
    //     dispatch(updateId(data.user.id));
    //     console.log("navigation")
        
    // }else{
    // toast.current.show({ severity: 'error', summary: 'Error', detail: 'Not a Admin', life: 3000 });
    // }


    return (
        <Box sx={{ width: "100%",height:"100vh",display:{xs: "block", sm:"flex"}}}>
            <Toast ref={toast} />
                <Box sx={{ width:{ sm:"55%", md:"65%"} ,height:{ xs:"60%", sm:"auto"} , display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Box component="img" src={hero} sx={{ width: "100%", height: "100%", objectFit:"cover" }}></Box>
                </Box>
                <Box sx={{ width: { sm:"45%", md:"35%"},height:{ xs:"40%", sm:"auto"}, display: "flex", justifyContent: "center", alignItems: "center",flexDirection:"column",gap:"20px" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                        <Typography sx={{ color: "text.login", fontWeight: "400",fontSize:"14px", textAlign: "left", width: "100%" }}>Mobile Number</Typography>
                        <InputBase name="mobileno" sx={{ backgroundColor: "#fff", height: "36px", borderRadius: "2px", padding: "12px 8px",border:"1px solid",borderColor:"secondary.border" ,width:"268px",fontSize:"14px",fontWeight:"400"}} placeholder="Enter registered mobile number" onChange={handleChange} />
                    </Box>
                    <Button variant="contained" onClick={(e)=>handleSubmit(e)} sx={{ width: "40%",width:"268px",height:"32px",borderRadius:"2px" }}>Log in </Button>
                </Box>
        </Box>
    )
}
import { Box, Skeleton, Typography } from "@mui/material";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect } from 'react';
import axios from 'axios';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { updateInventory, updateInventoryDetail, updateInventoryDetailsModal, updateInventoryModal, updateInventoryform, updateallInventryDetails, updateinventoryEdit } from "../Redux/inventory";
import { useState } from "react";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import Tooltip from '@mui/material/Tooltip';
import CustomAxios from "../utils/CustomAxios";
import { useGetInventory, useGetInventoryDetails } from "../Components/globalFunctions";

export function InventoryTable() {
    const authToken = localStorage.getItem('authtoken');
    const toast = useRef(null);
    const edit = useSelector(state => state.inventory.inventoryEdit)
    const inventory = useSelector(state => state.inventory.inventory);
    const inventoryForm = useSelector(state => state.inventory.inventoryForm);
    const supervisors = useSelector(state => state.inventory.supervisors)
    const [details, setDetails] = useState([])
    const inventoryLoading  = useSelector(state => state.inventory.inventoryLoading);
    const dispatch = useDispatch();
    const getInventeroryDetails = useGetInventoryDetails()
    const getInventerory = useGetInventory()

    useEffect(()=>{
        if(inventory.length > 0){
            console.log("--------------------------------------------",inventory,supervisors)

        const addNamesToArray1 = (inventory, supervisors) => {
            return inventory.map(item => {
              const newItem = { ...item, names: [] };
          
              newItem.names = item.supervisors
                .flatMap(superid => supervisors
                  .filter(superv => superv.value === superid)
                  .map(superv => superv.name)
                );
          
              return newItem;
            });
          };
          
          const updatedArray1 = addNamesToArray1(inventory, supervisors);
    
        setDetails(updatedArray1)}
    },[inventory,supervisors,inventoryForm])

    const accept = async (id) => {
        console.log(id)
        const response = await CustomAxios.delete(`inventory/delete/${id}`, {
            
        }).then(res => {
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Inventory Deleted Successfully', life: 3000 });
            getInventeroryDetails()
            getInventerory()
        }).catch(err => { console.log(err) });
    }

    const confirmDelete = (item) => {
        console.log(item)
        confirmDialog({
            message: (
                <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
                    <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
                        <Box>
                            <Typography >Hubname: {item.name}</Typography>
                        </Box>
                    </Box>
                    <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>Do you want to delete this Inventory </Typography>
                </Box>
            ),
            header: 'Delete Confirmation',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: () => accept(item.value),
        });
    };

    const handleOpen = (row) => {
        let rowData = {
            ...row,
            place: row.name,
            supervisor_ids: row.supervisors,
        }
        dispatch(updateinventoryEdit(true))
        dispatch(updateInventoryform(rowData));
        dispatch(updateInventoryModal(true))
    };

    console.log(details)
    return (<Box>
        <Toast ref={toast} />
        <Typography sx={{fontSize: "16px", fontWeight: "700", lineHeight: "24px"}}>Inventory List</Typography>
        <TableContainer sx={{ height: "100%" }}>
            <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
                <TableHead style={{ backgroundColor: "background.upload" }}>
                    <TableRow >
                        <TableCell sx={{ fontWeight: "600", textAlign: "left", backgroundColor: "background.upload" }} align="left">S No</TableCell>
                        <TableCell sx={{ fontWeight: "600", textAlign: "left", backgroundColor: "background.upload" }} align="left">Hubname</TableCell>
                        <TableCell sx={{ fontWeight: "600", textAlign: "left", backgroundColor: "background.upload" }} align="left">Supervisors</TableCell>
                        <TableCell sx={{ fontWeight: "600", textAlign: "left", backgroundColor: "background.upload" }} align="left">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {details?.length>0?
                    details?.map((item,ind) => {
                        console.log(item)
                        return (<> {
                            <TableRow key={item.id}>
                                <TableCell sx={{ textAlign: "left", textTransform: "capitalize", backgroundColor: "white" }}>{ind+1}</TableCell>
                                <TableCell sx={{ textAlign: "left", textTransform: "capitalize", backgroundColor: "white" }}>{item.name}</TableCell>
                                <TableCell sx={{ textAlign: "left", textTransform: "capitalize", backgroundColor: "white" }}>{
                                item.names.map((name)=>(
                                    <Typography>{name}</Typography>
                                ))}
                                
                                </TableCell>
                                <TableCell sx={{ textAlign: "left", textTransform: "capitalize", backgroundColor: "white" }}>
                                <Tooltip title="Edit" arrow>
                                    <IconButton sx={{ textTransform: "none" }} onClick={() => handleOpen(item)}><EditIcon /></IconButton>
                                </Tooltip>
                                <Tooltip title="Delete" arrow>
                                    <IconButton sx={{ textTransform: "none" }} onClick={() => confirmDelete(item)}><DeleteOutlineIcon /></IconButton>
                                </Tooltip>
                                </TableCell>
                            </TableRow>}</>)
                    })
                    : <TableRow  >
                    {inventoryLoading?<TableCell sx={{ textAlign: "center", textTransform: "capitalize", backgroundColor: "white",height:"200px",fontWeight:"700" }} colSpan={7}>
                        <Box>
                            <Box sx={{ width: "80%" }}>
                                <Skeleton animation="wave" sx={{ height: "60px", background: "rgb(0 0 0 / 6%)" }} />
                                <Skeleton animation="wave" sx={{ background: "rgb(0 0 0 / 6%)" }} />
                            </Box>
                            <Box sx={{ width: "80%" }}>
                                <Skeleton animation="wave" sx={{ height: "60px", background: "rgb(0 0 0 / 6%)" }} />
                                <Skeleton animation="wave" sx={{ background: "rgb(0 0 0 / 6%)" }} />
                            </Box>
                            <Box sx={{ width: "80%" }}>
                                <Skeleton animation="wave" sx={{ height: "60px", background: "rgb(0 0 0 / 6%)" }} />
                                <Skeleton animation="wave" sx={{ background: "rgb(0 0 0 / 6%)" }} />
                            </Box>
                        </Box>
                    </TableCell>:
                    <TableCell sx={{ textAlign: "center", textTransform: "capitalize", backgroundColor: "white",height:"200px",fontWeight:"700" }} colSpan={7}>No Inventory Available</TableCell>}
                </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </Box>)
}
import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    username: '',
    email: '',
    mobileno: '',
    // password: '',
    id:'',
};


const userReducer = createSlice({
    name: "userReducer",
    initialState: initialState,
    reducers: {
        updateUsername: (state, {  payload }) => {
            state.first_name = payload
        },
        updateEmail: (state, {  payload }) => {
            state.email = payload
        },
        updateMobileno: (state, {  payload }) => {
            state.mobileno = payload
        },
        updateId: (state, {  payload }) => {
            state.id = payload
        },
    }
    })

    export const { updateUsername,updateEmail,updateMobileno,updateId} = userReducer.actions
    
    export default userReducer.reducer;
import axios from "axios";

const CustomAxiosDP = () => {
// const navigate=useNavigate()
console.log(process.env.REACT_APP_API_DP_URL)
  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_DP_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // Create instance
  const instance = axios.create(defaultOptions);

  const WHITELISTED_URLS = [
    "login/",
    "delivery_create/",
    "delivery_update",
    "delivery/user-details/"
  ]

  // Set the AUTH token for any protected request
  instance.interceptors.request.use((config) => {

    const access_token = localStorage.getItem('authtoken');
    //const refresh_token = localStorage.getItem('refreshToken_dp');
    // console.log(config.url)
    if (WHITELISTED_URLS.includes(config.url)) {
      return config;
    }

    if (access_token) {
      config.headers.Authorization = `Bearer ${access_token}`;
      console.log(config.url)
      if (config.url === "products/add/" || config.url.includes("products/update/")) {
        config.headers['Content-Type'] = 'application/octet-stream';
      }
      return config;
    } else {
      localStorage.clear();
      window.location.href="/shino-partner"
      return config;
    }

  },
    (error) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    res => res,
    async (err) => {
      const originalConfig = err.config;

      if (!WHITELISTED_URLS.includes(originalConfig.url) && err.response?.status === 401) {

        let errorCode;
        if (err.config.responseType === 'blob') {
          const blobToJson = async (blob) => {
            try {
              const text = await blob.text();
              const json = JSON.parse(text);
              return json;
            } catch (error) {
              console.error("Error parsing blob as JSON:", error);
              return null;
            }
          }
          await blobToJson(err.response.data).then(jsonObj => errorCode = jsonObj.code);
        }
        else if (err.config.responseType === 'arraybuffer') {
          const arrayBuffer = err.response.data
          const textDecoder = new TextDecoder('utf-8');
          const jsonString = textDecoder.decode(arrayBuffer);
          try {
            const jsonObj = JSON.parse(jsonString);
            errorCode = jsonObj.code;
          } catch (error) {
            console.error("Error parsing Array Buffer as JSON:", error);
          }
        } else {
          errorCode = err.response.data.code
        }

        if (!originalConfig._retry && errorCode === "ATExp") {
          // console.log("here")
          originalConfig._retry = true;

          try {
            const rs = await axios.post(defaultOptions.baseURL + "refresh_token/", {
              refreshToken: localStorage.getItem("refreshToken_dp")
            });

            const { access_token } = rs.data;
            localStorage.setItem("authtoken_dp", access_token);

            return instance(originalConfig)

          } catch (error) {
            if ((error.response?.status === 401 ||error.response?.status===500) ) {
              localStorage.clear();
              window.location.href="/shino-partner";
            }
            else if(!error.response){
              localStorage.clear();
              // navigate('/', { replace: true });
              window.location.href="/shino-partner";
            }
            return Promise.reject(error)
          }
        }
      }
      return Promise.reject(err);
    }
  )

  return instance;
};

export default CustomAxiosDP();
import { Box, Skeleton, Typography } from "@mui/material";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect } from 'react';
import axios from 'axios';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import veg from "../Assets/product_management/Foodmark.svg"
import nonVeg from "../Assets/product_management/non-veg.svg"
import { updateInventoryDetail, updateInventoryDetailsModal, updateInventryHubsupervisors, updateallInventryDetails, updateinventoryDetailsEdit } from "../Redux/inventory";
import { useState } from "react";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import Tooltip from '@mui/material/Tooltip';
import CustomAxios from "../utils/CustomAxios";
import { useGetInventoryDetails } from "../Components/globalFunctions";

export function InventoryDetailsTable() {
    const authToken = localStorage.getItem('authtoken');
    const toast = useRef(null);
    const products = useSelector(state => state.products.products)
    const edit = useSelector(state => state.inventory.inventoryDetailsEdit)
    const search = useSelector(state => state.page.searchProduct)
    const inventoryDetails = useSelector(state => state.inventory.allInventryDetails)
    const inventories = useSelector(state => state.inventory.inventory)
    const supervisors = useSelector(state => state.inventory.supervisors)
    const [details, setDetails] = useState([])
    const getInventeroryDetails = useGetInventoryDetails()
    const inventoryDetailsLoading  = useSelector(state => state.inventory.inventoryDetailsLoading);

    const dispatch = useDispatch();
    useEffect(()=>{
        setDetails(inventoryDetails)
    },[inventoryDetails])

    useEffect(() => {
        let filtered = [];
        if (inventoryDetails?.length > 0 && products?.length > 0 && inventories?.length > 0) {
            const updatedInventoryDetails = inventoryDetails?.map((inventory) => {
                if (products?.some(p => p.value === inventory.product_id)) {
                    const product = products.find(p => p.value === inventory.product_id);
                    filtered = inventories?.filter((item) => item.name == inventory.place)
                    console.log("------", filtered);
                    if(filtered.length>0){
                          return {
                        ...inventory,
                        // details_id: filtered[0].value,
                        // inventoryDetails_id_original: inventory.id,
                        ...product
                    };
                    }
                    else{
                        return {
                            ...inventory,
                        };
                    }
                  
                }
                console.log(inventory)
                return inventory;
            });
            if (inventoryDetails?.length > 0 && supervisors?.length > 0 && filtered?.length > 0 ) {
                const commonNames = supervisors?.filter(obj1 => filtered[0].supervisors?.some(obj2 => obj1.id === obj2.id))
                    .map(obj => obj.name);
                console.log(commonNames);
                dispatch(updateInventryHubsupervisors(commonNames))
            }

            setDetails(updatedInventoryDetails)
        }
    }, [inventoryDetails, products, inventories, supervisors]);

    const accept = async (id) => {
        console.log(id)
        const response = await CustomAxios.delete(`products/delete/${id}`, {
          
        }).then(res => {
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Inventory details Deleted Successfully', life: 3000 });
            getInventeroryDetails()
        }).catch(err => { console.log(err) });
    }

    const confirmDelete = (item) => {
        confirmDialog({
            message: (
                <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
                    <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
                        {item.image_urls.map((img) => (<Box component='img' src={img} sx={{ width: "40px", height: "40px", borderRadius: "8px", marginRight: "10px" }}></Box>))}
                        <Box>
                            <Typography >Product Name: {item.item_name}</Typography>
                            <Typography >Hub name: {item.place}</Typography>
                        </Box>
                    </Box>
                    <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>Do you want to delete this Inventory Details </Typography>
                </Box>
            ),
            header: 'Delete Confirmation',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: () => accept(item.id),
        });
    };

    const handleOpen = (row) => {
        dispatch(updateinventoryDetailsEdit(true))
        dispatch(updateInventoryDetail(row));
        dispatch(updateInventoryDetailsModal(true))
        
    };
console.log(edit)

    return (<Box>
        <Toast ref={toast} />
        <Typography sx={{fontSize: "16px", fontWeight: "700", lineHeight: "24px"}}>Inventory Details List</Typography>
        <TableContainer sx={{ height: "100%" }}>
            <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
                <TableHead style={{ backgroundColor: "background.upload" }}>
                    <TableRow >
                        <TableCell sx={{ fontWeight: "600", textAlign: "left", backgroundColor: "background.upload" }} align="left">S No</TableCell>
                        <TableCell sx={{ fontWeight: "600", textAlign: "left", backgroundColor: "background.upload" }} align="left">Images</TableCell>
                        <TableCell sx={{ fontWeight: "600", textAlign: "left", backgroundColor: "background.upload" }} align="left">Place</TableCell>
                        <TableCell sx={{ fontWeight: "600", textAlign: "left", backgroundColor: "background.upload" }} align="left">Products</TableCell>
                        <TableCell sx={{ fontWeight: "600", textAlign: "left", backgroundColor: "background.upload" }} align="left">Avaiable Units</TableCell>
                        <TableCell sx={{ fontWeight: "600", textAlign: "left", backgroundColor: "background.upload" }} align="left">Total Units</TableCell>
                        <TableCell sx={{ fontWeight: "600", textAlign: "left", backgroundColor: "background.upload" }} align="right">Category</TableCell>
                        <TableCell sx={{ fontWeight: "600", textAlign: "left", backgroundColor: "background.upload" }} align="right">Criteria</TableCell>
                        <TableCell sx={{ fontWeight: "600", textAlign: "left", backgroundColor: "background.upload" }} align="left">Actions</TableCell>


                    </TableRow>
                </TableHead>
                <TableBody>
                    {details?.length>0?
                    details?.map((item,ind) => {
                        console.log(item)
                        return (<> {
                            <TableRow key={item.id}>
                                <TableCell sx={{ textAlign: "left", textTransform: "capitalize", backgroundColor: "white" }}>{ind+1}</TableCell>
                                <TableCell sx={{ textAlign: "left", textTransform: "capitalize", backgroundColor: "white" }}>
                                    {item?.image_urls?.map((img) => (<Box component='img' src={img} sx={{ width: "40px", height: "40px", borderRadius: "8px", marginRight: "10px" }}></Box>))}
                                </TableCell>
                                <TableCell sx={{ textAlign: "left", textTransform: "capitalize", backgroundColor: "white" }}>{item.place}</TableCell>
                                <TableCell sx={{ textAlign: "left", textTransform: "capitalize", backgroundColor: "white" }}>{item.item_name}</TableCell>
                                <TableCell sx={{ textAlign: "left", textTransform: "capitalize", backgroundColor: "white" }}>{item.available_units}</TableCell>
                                <TableCell sx={{ textAlign: "left", textTransform: "capitalize", backgroundColor: "white" }}>{item.total_units}</TableCell>
                                <TableCell sx={{ textAlign: "left", textTransform: "capitalize", backgroundColor: "white" }}>{item.item_category}</TableCell>
                                <TableCell sx={{ textAlign: "left", textTransform: "capitalize", backgroundColor: "white" }}>{item.item_criteria == 'veg' ? <Box component='img' src={veg}></Box> : <Box component='img' src={nonVeg}></Box>}</TableCell>
                                <TableCell sx={{ textAlign: "left", textTransform: "capitalize", backgroundColor: "white" }}>
                                <Tooltip title="Edit" arrow>
                                    <IconButton sx={{ textTransform: "none" }} onClick={() => handleOpen(item)}><EditIcon /></IconButton>
                                </Tooltip>
                                <Tooltip title="Delete" arrow>
                                    <IconButton sx={{ textTransform: "none" }} onClick={() => confirmDelete(item)}><DeleteOutlineIcon /></IconButton>
                                </Tooltip>
                                </TableCell>
                            </TableRow>}</>)
                    })
                    : <TableRow  >
                    {inventoryDetailsLoading?<TableCell sx={{ textAlign: "center", textTransform: "capitalize", backgroundColor: "white",height:"200px",fontWeight:"700" }} colSpan={9}>
                        <Box>
                            <Box sx={{ width: "80%" }}>
                                <Skeleton animation="wave" sx={{ height: "60px", background: "rgb(0 0 0 / 6%)" }} />
                                <Skeleton animation="wave" sx={{ background: "rgb(0 0 0 / 6%)" }} />
                            </Box>
                            <Box sx={{ width: "80%" }}>
                                <Skeleton animation="wave" sx={{ height: "60px", background: "rgb(0 0 0 / 6%)" }} />
                                <Skeleton animation="wave" sx={{ background: "rgb(0 0 0 / 6%)" }} />
                            </Box>
                            <Box sx={{ width: "80%" }}>
                                <Skeleton animation="wave" sx={{ height: "60px", background: "rgb(0 0 0 / 6%)" }} />
                                <Skeleton animation="wave" sx={{ background: "rgb(0 0 0 / 6%)" }} />
                            </Box>
                        </Box>
                    </TableCell>:
                    <TableCell sx={{ textAlign: "center", textTransform: "capitalize", backgroundColor: "white",height:"200px",fontWeight:"700" }} colSpan={7}>No Inventory Details Available</TableCell>}
                </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </Box>)
}
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomAxiosDP from "../utils/CustomAxiosDP";
import { jwtDecode } from "jwt-decode";

export function DeliveryEarnings(){
    const [orders, setOrders] = useState(0)
    const [earnings, setEarnings] = useState(0.00)
    const authToken = localStorage.getItem('authtoken')
    const decodedToken = jwtDecode(authToken);
    const user_id = decodedToken.id

    const deliveryEarnings = async (user_id)=>{
        const payload={
            partner_id:user_id
        }
        await CustomAxiosDP.post('earning/', payload).then((res)=>{
            setOrders(res.data.orders)
            setEarnings(res.data.earnings)
        })
    }

    useEffect(()=>{
        deliveryEarnings(user_id)
    },[user_id])


    return(
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", width: "100%" ,padding:"30px"}}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <Box sx={{display:"flex",justifyContent:"space-between"}}>
                     <Typography sx={{ fontSize: "16px", fontWeight: "700", lineHeight: "24px" }}>Delivery Earnings</Typography>
                </Box>
                <Box sx={{display:"flex",gap:"20px",marginBottom:"30px"}}>
                        <Box sx={{height:"100px",maxWidth:"172px",width:"100%",backgroundColor:"#FDE9F2",borderRadius:"8px",boxShadow:"10px 10px 15px 0px #ED1E7933",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",border:"1px solid #F8A5C9"}}>
                            <Typography sx={{ fontSize: "24px", fontWeight: "500", lineHeight: "32px" }}>{orders}</Typography>
                            <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22px" }}>Completed Orders</Typography>
                        </Box>
                        <Box sx={{height:"100px",maxWidth:"172px",width:"100%",backgroundColor:"#F0FDF4",borderRadius:"8px",boxShadow:"10px 10px 15px 0px #4ADE8033",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",border:"1px solid #86EFAC"}}>
                            <Typography sx={{ fontSize: "24px", fontWeight: "500", lineHeight: "32px" }}>Rs. {earnings}</Typography>
                            <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22px" }}>Total Earnings</Typography>
                        </Box>
                     </Box>
            </Box>
        </Box>
    )
}
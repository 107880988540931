import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect } from 'react';
import axios from 'axios';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useDispatch, useSelector } from 'react-redux';
import { updateEdit, updateModal, updateProductDetail, updateProducts } from '../Redux/products';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import { Button, Skeleton, Typography } from '@mui/material';
import { useState } from 'react';
import veg from "../Assets/product_management/Foodmark.svg"
import nonVeg from "../Assets/product_management/non-veg.svg"
import Tooltip from '@mui/material/Tooltip';
import CustomAxios from '../utils/CustomAxios';
import { useGetProducts } from '../Components/globalFunctions';

export function ProductTable() {
  const authToken = localStorage.getItem('authtoken');
  const toast = useRef(null);
  const products = useSelector(state => state.products.products)
  const search = useSelector(state => state.page.searchProduct)
  const dispatch = useDispatch()
  const loading  = useSelector(state => state.products.loading);
  const getProducts = useGetProducts()

  const accept = async (id) => {
    console.log(id)
    const response = await CustomAxios.delete(`products/delete/${id}`, {
      
    }).then(res => {
      getProducts()
      toast.current.show({ severity: 'success', summary: 'Success', detail: 'Product Deleted Successfully', life: 3000 });
    }).catch(err => { console.log(err) });
  }

  useEffect(() => {
    getProducts()
  }, [authToken])



  const confirmDelete = (item) => {
    confirmDialog({
      message: (
        <Box sx={{ display: "flex", gap: "10px",flexDirection:"column" }}>
          <Box sx={{ display: "flex", gap: "20px",alignItems:"center" }}>
            {item.image_urls.map((img) => (<Box component='img' src={img} sx={{ width: "40px", height: "40px", borderRadius: "8px", marginRight: "10px" }}></Box>))}
            <Box >{item.item_name}</Box>
          </Box>
          <Typography sx={{fontSize:"16px",fontWeight:500}}>Do you want to delete this Product </Typography>
        </Box>
      ),
      header: 'Delete Confirmation',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: () => accept(item.id),
    });
  };

   const handleOpen = (row) => {
    console.log(row)
    dispatch(updateEdit(true))
    dispatch(updateProductDetail(row));
    dispatch(updateModal(true))  
  };
console.log(products)
  return (
    <Box>
      <Toast ref={toast} />
      <ConfirmDialog />
      <TableContainer sx={{ height: "600px" }}>
        <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
          <TableHead style={{ backgroundColor: "background.upload" }}>
            <TableRow >
              <TableCell sx={{ fontWeight: "600", textAlign: "center", backgroundColor: "background.upload",textAlign:"left" }}>S No</TableCell>
              <TableCell sx={{ fontWeight: "600", textAlign: "center", backgroundColor: "background.upload",textAlign:"left" }}>Images</TableCell>
              <TableCell sx={{ fontWeight: "600", textAlign: "center", backgroundColor: "background.upload",textAlign:"left" }}>Name</TableCell>
              <TableCell sx={{ fontWeight: "600", textAlign: "center", backgroundColor: "background.upload",textAlign:"left" }} align="right">Description</TableCell>
              <TableCell sx={{ fontWeight: "600", textAlign: "center", backgroundColor: "background.upload",textAlign:"left" }} align="right">Details</TableCell>
              <TableCell sx={{ fontWeight: "600", textAlign: "center", backgroundColor: "background.upload",textAlign:"left" }} align="right">Category</TableCell>
              <TableCell sx={{ fontWeight: "600", textAlign: "center", backgroundColor: "background.upload",textAlign:"left" }} align="right">Criteria</TableCell>
              {/* <TableCell sx={{ fontWeight: "600", textAlign: "center", backgroundColor: "background.upload",textAlign:"left" }} align="right">Availability</TableCell> */}
              {/* <TableCell sx={{ fontWeight: "600", textAlign: "center", backgroundColor: "background.upload",textAlign:"left" }} align="right">Price</TableCell>
              <TableCell sx={{ fontWeight: "600", textAlign: "center", backgroundColor: "background.upload",textAlign:"left" }} align="right">Selling Price</TableCell>
              <TableCell sx={{ fontWeight: "600", textAlign: "center", backgroundColor: "background.upload",textAlign:"left" }} align="right">Offer Price</TableCell> */}
              <TableCell sx={{ fontWeight: "600", textAlign: "center", backgroundColor: "background.upload",textAlign:"left" }} align="right">Update / Delete</TableCell>


            </TableRow>
          </TableHead>
          <TableBody>
            {products?.length>0?
            products?.filter((item) => {
    if (!item.item_name) return false;

    return search !== ""
      ? item.item_name.toLowerCase().includes(search?.toLowerCase())
      : true; }).map((item,ind) => {
              console.log(item.item_criteria)
              return (<> {
                <TableRow key={item.id}>
                  <TableCell sx={{ textAlign: "left", textTransform: "capitalize", backgroundColor: "white" }}>{ind+1}</TableCell>
                  <TableCell sx={{ textAlign: "left", textTransform: "capitalize", backgroundColor: "white" }}>
                    {item.image_urls.map((img) => (<Box component='img' src={img} sx={{ width: "40px", height: "40px", borderRadius: "8px", marginRight: "10px" }}></Box>))}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", textTransform: "capitalize", backgroundColor: "white" }}>{item.item_name}</TableCell>
                  <TableCell sx={{ textAlign: "left", textTransform: "capitalize", backgroundColor: "white" }}>{item.item_description}</TableCell>
                  <TableCell sx={{ textAlign: "left", textTransform: "capitalize", backgroundColor: "white",maxWidth:"200px" }}>
                  <Tooltip sx={{width:"500px"}} title={item.item_details} arrow>
                    <Typography >{item.item_details.split(' ').slice(0, 3).join(' ')}...</Typography>
                  </Tooltip>
                   </TableCell>
                  <TableCell sx={{ textAlign: "left", textTransform: "capitalize", backgroundColor: "white" }}>{item.item_category}</TableCell>
                  <TableCell sx={{ textAlign: "left", textTransform: "capitalize", backgroundColor: "white" }}>{item.item_criteria=='veg'?<Box component='img' src={veg}></Box>:<Box component='img' src={nonVeg}></Box>}</TableCell>
                  {/* <TableCell sx={{ textAlign: "center", textTransform: "capitalize", backgroundColor: "white" }}>{item.capacitydetails.item_available==true?"Available":"Out of stock"}</TableCell> */}
                  {/* <TableCell sx={{ textAlign: "center", textTransform: "capitalize", backgroundColor: "white" }}>{item.capacitydetails.item_price}</TableCell>
                  <TableCell sx={{ textAlign: "center", textTransform: "capitalize", backgroundColor: "white" }}>{item.capacitydetails.item_selling_price}</TableCell>
                  <TableCell sx={{ textAlign: "center", textTransform: "capitalize", backgroundColor: "white" }}>{item.capacitydetails.item_offers_price}</TableCell> */}
                  <TableCell sx={{ textAlign: "left", textTransform: "capitalize", backgroundColor: "white" }}>
                  <Tooltip title="Edit" arrow>
                    <IconButton sx={{ textTransform: "none" }} onClick={() => handleOpen(item)}><EditIcon /></IconButton>
                    </Tooltip>
                <Tooltip title="Delete" arrow>
                    <IconButton sx={{ textTransform: "none" }} onClick={() => confirmDelete(item)}><DeleteOutlineIcon /></IconButton>
                                </Tooltip>

                  </TableCell>
                </TableRow>}</>)
            })
            : <TableRow  >
            {loading?<TableCell sx={{ textAlign: "center", textTransform: "capitalize", backgroundColor: "white",height:"200px",fontWeight:"700" }} colSpan={8}>
                <Box>
                    <Box sx={{ width: "80%" }}>
                        <Skeleton animation="wave" sx={{ height: "60px", background: "rgb(0 0 0 / 6%)" }} />
                        <Skeleton animation="wave" sx={{ background: "rgb(0 0 0 / 6%)" }} />
                    </Box>
                    <Box sx={{ width: "80%" }}>
                        <Skeleton animation="wave" sx={{ height: "60px", background: "rgb(0 0 0 / 6%)" }} />
                        <Skeleton animation="wave" sx={{ background: "rgb(0 0 0 / 6%)" }} />
                    </Box>
                    <Box sx={{ width: "80%" }}>
                        <Skeleton animation="wave" sx={{ height: "60px", background: "rgb(0 0 0 / 6%)" }} />
                        <Skeleton animation="wave" sx={{ background: "rgb(0 0 0 / 6%)" }} />
                    </Box>
                </Box>
            </TableCell>:
            <TableCell sx={{ textAlign: "center", textTransform: "capitalize", backgroundColor: "white",height:"200px",fontWeight:"700" }} colSpan={7}>No Products Available</TableCell>}
        </TableRow>}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>

  );
}

import { useDispatch, useSelector } from "react-redux"
import CustomAxios from "../utils/CustomAxios"
import { updateLoading, updateOrderDetails, updateOrderModel, updateOrders, updatePartnerAssignModal, updateUpdatedDate, updateassignedOrders, updatecompletedOrders, updatefilteredOrders, updatependingOrders, updateselectedDeliveryPartner } from "../Redux/orders"
import { updateLoadingProducts, updateProducts } from "../Redux/products";
import { updateInventory, updateInventoryDetailsLoading, updateInventoryDetailsModal, updateInventoryLoading, updateInventoryModal, updateInventoryform, updateallInventryDetails } from "../Redux/inventory";
import dayjs from "dayjs";
import { Box, Button, MenuItem, Modal, Select, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { Toast } from 'primereact/toast';
import { OrderProductsTable } from "../sub_components/ordered_products_table";
import CustomAxiosDP from "../utils/CustomAxiosDP";
import { format } from 'date-fns';

export const useGetOrders = () => {
    const dispatch = useDispatch();
    const today = dayjs()
    const formatdate = useDateformate()

    const getOrders = async () => {
        dispatch(updateLoading(true))
        try {
            const response = await CustomAxios.get(`get_all_orders/`);
            dispatch(updateOrders(response.data));
            dispatch(updateLoading(false))
        if(response.data.length>0){
            const pendingOrders = response.data.filter((item) => 
                (item.status === "pending" && formatdate(item.created_on)==today.format('DD-MM-YYYY'))
            ) 
            const assignedOrders = response.data.filter((item) => item.status === "delivery_partner_assigned") 
            const completedOrders = response.data.filter((item) => item.status === "delivered") 
            dispatch(updatependingOrders(pendingOrders))
            dispatch(updateassignedOrders(assignedOrders))
            dispatch(updatecompletedOrders(completedOrders))
         } else{
                dispatch(updatependingOrders(0))
                dispatch(updateassignedOrders(0))
                dispatch(updatecompletedOrders(0))
            }
        } catch (error) {
            console.error("Error fetching orders:", error);
            dispatch(updateLoading(false))
        }
    };

    return getOrders;
};



export const useGetProducts = () => {
    const dispatch = useDispatch();
    dispatch(updateLoadingProducts(true))
    const getDetails = async () => {
        try {
            const response = await CustomAxios.get(`products/`, {
            }).then(res => {
                const options = res?.data?.map((item) => ({
                    name: item.item_name,
                    value: item.id,
                    image: item.image_urls[0],
                    ...item
                }));
                dispatch(updateProducts(options))
                dispatch(updateLoadingProducts(false))
            })
        }
        catch (error) {
            console.error("Error fetching orders:", error);
            dispatch(updateLoadingProducts(false))
        }

    }
    return getDetails;
};


export const useGetInventory = () => {
    const dispatch = useDispatch();
    dispatch(updateInventoryLoading(true))

    const getInventerory = async () => {
        try {
            const inventory = await CustomAxios.get(`inventory/view`, {
            }).then(res => {
                console.log(res)
                const options = res?.data?.map((item) => ({
                    name: item.place,
                    value: item.id,
                    supervisors: item.supervisors,
                }));
                dispatch(updateInventoryform({
                    place: '',
                    supervisor_ids: []
                },))
                dispatch(updateInventoryModal(false))
                options && dispatch(updateInventory(options))
            })
        }
        catch (error) {
            console.error("Error fetching orders:", error);
            dispatch(updateInventoryLoading(false))
        }
    }

    return getInventerory;
};

export const useGetInventoryDetails = () => {
    const dispatch = useDispatch();
    dispatch(updateInventoryDetailsLoading(true))

    const getInventeroryDetails = async () => {
        try {
            const inventory = await CustomAxios.get(`inventory/details/view`, {
            }).then(res => {
                const options = res?.data?.map((item) => ({
                    ...item,
                    details_id: item.details,
                }));
                dispatch(updateallInventryDetails(options))
                dispatch(updateInventoryDetailsModal(false))
            })
        }
        catch (error) {
            console.error("Error fetching orders:", error);
            dispatch(updateInventoryDetailsLoading(false))
        }
    }

    return getInventeroryDetails;
};


export const useFetchOrders = () => {
    const dispatch = useDispatch();
    const actorders = useSelector(state => state.orders.orders);
    const formatDate = useDateformate()

    const fetchOrders = (date, deliverypartner) => {
        console.log(date)
        let filteredOrders = []
        let orders = actorders
        if(deliverypartner){
           orders = actorders.filter(order=>{if (order.delivery_partner_id==deliverypartner?.id){
                return order
            }})
        }
        if(typeof date === 'string'){
            const formattedDate = formatDate(date)
            console.log(`Fetching orders for ${formattedDate}`);
             filteredOrders = orders.filter(order => {
                const orderDate = dayjs(order.created_on).format('DD-MM-YYYY');
                console.log(orderDate, formattedDate);
                return orderDate === formattedDate;
            });
        }else{
            const formattedDate = date.format('DD-MM-YYYY');
        console.log(`Fetching orders for ${formattedDate}`);
         filteredOrders = orders.filter(order => {
            const orderDate = dayjs(order.created_on).format('DD-MM-YYYY');
            console.log(orderDate, formattedDate);
            return orderDate === formattedDate; 
        });
        }
        
        
        console.log(filteredOrders);
          dispatch(updatefilteredOrders(filteredOrders));
          console.log(filteredOrders)
      };

    return fetchOrders;
};

export const useDateformate = () => {

    const formatDate = (dateString) => {
        const dateObject = new Date(dateString);
        const day = String(dateObject.getDate()).padStart(2, '0');
        const month = String(dateObject.getMonth() + 1).padStart(2, '0');
        const year = dateObject.getFullYear();
        return `${day}-${month}-${year}`;
    };

    return formatDate;
};

export const OrderModal = () => {
    const dispatch = useDispatch();
    const orderedProducts = useSelector(state => state.orders.orderDetails);
    const [loading, setLoading] = useState(false);
    const partners = useSelector(state => state.orders.deliveryPartners);
    const [deliveryPartners,setDeliveryPartners] = useState([])
    const orderModal = useSelector(state => state.orders.orderModal);
    const GetOrders = useGetOrders()
    const formatDate = useDateformate()
    const toast = useRef(null);
    const deliveryMode = 1
    const [orderDetails,setOrdersDetails] = useState({})
    useEffect(()=>{
        setOrdersDetails(orderedProducts)
    },[orderedProducts])
    useEffect(()=>{
        setDeliveryPartners(partners)
    },[partners])
    const handleCloseOrderModal = () => {
        dispatch(updateOrderModel(false))
        dispatch(updateOrderDetails({}))
        dispatch(updateselectedDeliveryPartner(""))
    };
    const handleStatusChange = async (id, newStatus,partner) => {
        try {
            setLoading(true);
            await CustomAxios.put(`checkout/update/${id}/`, { status: newStatus,partnerId:partner }).then((res) => {
                console.log(res.status)
                if (res?.status == 200) {
                    console.log("hereee")
                    if(newStatus =="delivery_partner_assigned"){
                        console.log("we were here first")
                        delivery_update(id, partner)
                        toast.current.show({ severity: 'success', summary: 'Success', detail: `Delivery Partner Assigned Successfully`, life: 3000 })
                        dispatch(updateselectedDeliveryPartner(res.data.order.delivery_partner_id))
                        dispatch(updatePartnerAssignModal(false))
                        // dispatch(updateOrderModel(false))
                        dispatch(updateOrderDetails(res.data.order))
                    }
                    else{
                        toast.current.show({ severity: 'success', summary: 'Success', detail: `Order ${res.data.order.status} Successfully`, life: 3000 })
                        dispatch(updateOrderDetails(res.data.order))
                    }
                    
                    GetOrders()
                    dispatch(updateUpdatedDate(partner.created_on))
                }
            })

        } catch (err) {
            console.error("Error updating status:", err);
        } finally {
            setLoading(false); // End loading
        }
    };
    const delivery_update = async(id, partner) => {
        console.log("we are here", typeof(partner), deliveryPartners)
        const delivery_partner = deliveryPartners.find((part)=>part.id==partner)
        console.log("////////////////////////////////////",delivery_partner)
        const response = await CustomAxiosDP.post('delivery_create/',{mobileno:delivery_partner.mobileno, delivery_mode:deliveryMode, delivery_status:"Pending", user_checkout_id:id}).then((response)=>{
            console.log(response.status)
            console.log(response.data.message)
        })
    }
    console.log(orderModal)
    return (
        <Box>
            <Toast ref={toast} />
            <Modal
                open={orderModal}
                onClose={handleCloseOrderModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ zIndex: 1000 }}
            >
                <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '90%', sm: '80%', md: '1000px' }, // Responsive width
                    maxWidth: "1000px",
                    height: { xs: 'auto', md: 'auto' }, // Responsive height
                    maxHeight: '90vh', // Limit modal height on mobile
                    backgroundColor: 'white',
                    overflowY: {xs:'auto', md:'unset'}, // Scroll if content is too long
                    borderRadius: 2,
                }}
                >
                <Box sx={{ padding: "12px 24px", backgroundColor: "#EEEEEE", display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontSize: { xs: "12px", md: "14px" }, fontWeight: "500" }}>
                    Order ID: {orderDetails.order_id}
                    </Typography>
                </Box>

                <Box sx={{ padding: "24px", gap: "20px", display: "flex", flexDirection: "column" }}>
                    <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: { xs: "12px", md: "24px" }, width: "100%" }}>
                    {/* Customer Details */}
                    <Box sx={{ display: "flex", gap: "4px", flexDirection: "column", width: { xs: "100%", md: "350px" } }}>
                        <Typography sx={{ fontSize: "14px", fontWeight: "700" }}>Customer Details</Typography>
                        <Box>
                        <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>{orderDetails?.delivery_address?.door_flat_no}, {orderDetails?.delivery_address?.address}</Typography>
                        <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>{orderDetails?.delivery_address?.pin}</Typography>
                        </Box>
                    </Box>
                    {/* Other Details */}
                    <Box sx={{ display: "flex", gap: "4px", flexDirection: "column", width: { xs: "100%", md: "200px" } }}>
                        <Typography sx={{ fontSize: "14px", fontWeight: "700" }}>Order on</Typography>
                        <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>{formatDate(orderDetails.created_on)}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: "4px", flexDirection: "column", width: { xs: "100%", md: "200px" } }}>
                                        <Typography sx={{ fontSize: "14px", fontWeight: "700", lineHeight: "22px" }}>Order Status</Typography>
                                        <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22px" }}>{orderDetails.status}</Typography>
                                        {/* <Select
                                            labelId={`status-label-${orderDetails.id}`}
                                            value={orderDetails.status}
                                            onChange={(e) => handleStatusChange(orderDetails.id, e.target.value, orderDetails)}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        backgroundColor: 'white',
                                                    },
                                                },
                                            }}
                                            sx={{ textAlign:"left",width: "200px", height: "32px", backgroundColor: orderDetails.status == "declined"?"#FFF1F2":orderDetails.status == "delivered" ? "#F6FFED" : orderDetails.status == "pending"?"#E6F1F8":orderDetails.status == "delivery_partner_assigned"?"#FFF7EB":"#F9FAFB",
                                            color: orderDetails.status == "declined"?"#E11D48":orderDetails.status == "delivered" ? "#52C41A" :orderDetails.status == "pending"?"#0071BC":orderDetails.status == "delivery_partner_assigned"?"#FBB03B": "#6B7280",
                                            borderColor: orderDetails.status == "declined"?"#E11D48":orderDetails.status == "delivered" ? "#F6FFED" : orderDetails.status == "pending"?"#E6F1F8":orderDetails.status == "delivery_partner_assigned"?"#FBB03B":"#F9FAFB",border:"1 px solid" }}
                                        >
                                            <MenuItem value="pending" >Pending</MenuItem>
                                            <MenuItem value="accepted"  >Accepted</MenuItem>
                                            <MenuItem value="declined"  >Declined</MenuItem>
                                            <MenuItem value="delivery_partner_assigned" >Delivery partner assigned</MenuItem>
                                            <MenuItem value="delivery_partner_accepted" >Delivery partner accepted</MenuItem>
                                            <MenuItem value="delivered" >Delivered</MenuItem>
                                        </Select> */}
                                    </Box>
                    {/* Delivery Partner Button */}
                    <Box sx={{ display: "flex", gap: "4px", flexDirection: "column", width: { xs: "100%", md: "200px" } }}>
                        <Typography sx={{ fontSize: "14px", fontWeight: "700" }}>Delivery Partner</Typography>
                        <Button variant="contained" sx={{ textTransform: "none", height: "32px", width: "100%" }} onClick={() => dispatch(updatePartnerAssignModal(true))}>
                        {orderDetails.delivery_partner_id == 0 || orderDetails.delivery_partner_id == null ? 'Assign' : 'Assigned'}
                        </Button>
                    </Box>
                    </Box>

                    <OrderProductsTable />
                </Box>

                <Box sx={{ backgroundColor: "#EEEEEE", display: "flex", justifyContent: "flex-end", padding: "12px 24px", gap: "20px" }}>
                    <Button variant="outlined" sx={{ textTransform: "none", color: "black" }} onClick={() => dispatch(updateOrderModel(false))}>
                    Close
                    </Button>
                    {/* Accept / Decline Buttons */}
                    <Button variant="outlined" disabled={orderDetails.status === 'declined'} sx={{ textTransform: "none", color: "black", backgroundColor: "white", width: "200px" }} onClick={() => handleStatusChange(orderDetails.id, 'declined')}>
                    {orderDetails.status == 'declined' ? 'Order Declined' : 'Decline'}
                    </Button>
                    <Button disabled={orderDetails.status === 'accepted' || orderDetails.status === 'delivered'} variant="contained" sx={{ textTransform: "none", width: "200px", backgroundColor: orderDetails.status === 'delivered' ? "#F6FFED" : "primary.button" }} onClick={() => handleStatusChange(orderDetails.id, 'accepted')}>
                    {orderDetails.status == 'accepted' ? 'Order Accepted' : 'Accept'}
                    </Button>
                </Box>
                </Box>
            </Modal>
        </Box>
            
    )
}

export const OrderModalDelivery = () => {
    const dispatch = useDispatch();
    const orderedProducts = useSelector(state => state.orders.orderDetails);
    const [loading, setLoading] = useState(false);
    const partners = useSelector(state => state.orders.deliveryPartners);
    const [deliveryPartners,setDeliveryPartners] = useState([])
    const orderModal = useSelector(state => state.orders.orderModal);
    const GetOrders = useGetOrders()
    const deliveryPartnerModal = useSelector(state => state.orders.partnerAssignModal);
    const selectedDeliveryPartner = useSelector(state => state.orders.selectedDeliveryPartner);
    const toast = useRef(null);
    const deliveryMode = 1
    const [orderDetails,setOrdersDetails] = useState({})
    useEffect(()=>{
        setOrdersDetails(orderedProducts)
    },[orderedProducts])
    useEffect(()=>{
        setDeliveryPartners(partners)
    },[partners])
    const handleClosedeliveryPartnerModal = () => {
        dispatch(updateselectedDeliveryPartner(""))
        dispatch(updatePartnerAssignModal(false))
    };
    const handleStatusChange = async (id, newStatus,partner) => {
        try {
            setLoading(true);
            await CustomAxios.put(`checkout/update/${id}/`, { status: newStatus,partnerId:partner }).then((res) => {
                console.log(res.status)
                if (res?.status == 200) {
                    console.log("hereee")
                    if(newStatus =="delivery_partner_assigned"){
                        console.log("we were here first")
                        delivery_update(id, partner)
                        toast.current.show({ severity: 'success', summary: 'Success', detail: `Delivery Partner Assigned Successfully`, life: 3000 })
                        dispatch(updateselectedDeliveryPartner(res.data.order.delivery_partner_id))
                        dispatch(updatePartnerAssignModal(false))
                        // dispatch(updateOrderModel(false))
                        dispatch(updateOrderDetails(res.data.order))
                    }
                    else{
                        toast.current.show({ severity: 'success', summary: 'Success', detail: `Order ${res.data.order.status} Successfully`, life: 3000 })
                        dispatch(updateOrderDetails(res.data.order))
                    }
                    
                    GetOrders()
                    dispatch(updateUpdatedDate(partner.created_on))
                }
            })

        } catch (err) {
            console.error("Error updating status:", err);
        } finally {
            setLoading(false); // End loading
        }
    };
    const delivery_update = async(id, partner) => {
        console.log("we are here", typeof(partner), deliveryPartners)
        const delivery_partner = deliveryPartners.find((part)=>part.id==partner)
        console.log("////////////////////////////////////",delivery_partner)
        const response = await CustomAxiosDP.post('delivery_create/',{mobileno:delivery_partner.mobileno, delivery_mode:deliveryMode, delivery_status:"Pending", user_checkout_id:id}).then((response)=>{
            console.log(response.status)
            console.log(response.data.message)
        })
    }
    console.log(orderModal)
    return (
        <Box>
            <Toast ref={toast} />
            <Modal
                open={deliveryPartnerModal}
                onClose={handleClosedeliveryPartnerModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ zIndex: 1300 }}
            >
                <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '90%', md: '294px' },
                    height: { xs: 'auto', md: '200px' },
                    backgroundColor: 'white',
                    borderRadius: 2,
                    paddingBottom: 2,
                }}
                >
                <Box sx={{ padding: "12px 24px", backgroundColor: "#EEEEEE", display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>ASSIGN DELIVERY PARTNER</Typography>
                </Box>
                <Box sx={{ padding: "20px", display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>Search Partner</Typography>
                    <Select
                    labelId={`status-label-${orderDetails.id}`}
                    value={selectedDeliveryPartner}
                    onChange={(e) => dispatch(updateselectedDeliveryPartner(e.target.value))}
                    MenuProps={{
                        PaperProps: { style: { backgroundColor: 'white' } },
                    }}
                    sx={{ width: "100%", height: "32px" }}
                    defaultValue=""
                    >
                    {deliveryPartners.length > 0 ? deliveryPartners.map(user => (
                        <MenuItem key={user.id} sx={{ background: "white" }} value={user.id}>
                        {user.first_name + " " + user.last_name}
                        </MenuItem>
                    )) : <MenuItem>No partners Available</MenuItem>}
                    </Select>
                </Box>
                <Box sx={{ backgroundColor: "#EEEEEE", display: "flex", justifyContent: "flex-end", padding: "12px 24px", gap: "20px" }}>
                    <Button variant="outlined" sx={{ textTransform: "none", color: "black" }} onClick={handleClosedeliveryPartnerModal}>Close</Button>
                    <Button variant="contained" disabled={selectedDeliveryPartner === "" || loading} sx={{ textTransform: "none" }} onClick={() => handleStatusChange(orderDetails.id, 'delivery_partner_assigned', selectedDeliveryPartner)}>
                    {loading ? 'Assigning...' : 'Assign'}
                    </Button>
                </Box>
                </Box>
            </Modal>
        </Box>
            
    )
};

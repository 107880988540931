import React, { useEffect, useRef } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import {
  updatedeliveryEdit,
  updatedeliveryModal,
  updateDeliveryDetail,
  updateDelivery,
} from "../Redux/deliveryUser";
import CustomAxiosDP from "../utils/CustomAxiosDP";

export function DeliveryTable() {
  const authToken = localStorage.getItem("authtoken");
  const toast = useRef(null);
  const delivery_user = useSelector((state) => state.deliveryUser.delivery);
  const search = useSelector((state) => state.page.searchDelivery);
  const dispatch = useDispatch();

  const getDeliveryDetails = async () => {

    // http://127.0.0.1:8000/
    try {
      const response = await CustomAxiosDP.get(`delivery/user-details/`);
      dispatch(updateDelivery(response.data));
    } catch (err) {
      console.error("Error fetching delivery data", err);
    }
  };

  useEffect(() => {
    getDeliveryDetails();
  }, []);
  

  console.log("searching................................", search);
// Filter delivery users based on the search term
const filteredDeliveryUsers = delivery_user.filter((item) =>
  item.username?.toLowerCase().includes(search?.toLowerCase() || "")
);

  const confirmDelete = (item) => {
    confirmDialog({
      message: (
        <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
          <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
            <Box
              component="img"
              src={item.partner_image}
              sx={{ width: "40px", height: "40px", borderRadius: "8px" }}
            />
            <Box>{item.username}</Box>
          </Box>
          <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
            Do you want to delete this Delivery Partner?
          </Typography>
        </Box>
      ),
      header: "Delete Confirmation",
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: () => accept(item.partner_id),
    });
  };

  const accept = async (id) => {
    try {
      await CustomAxiosDP.delete(`delivery/delete/${id}/`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      getDeliveryDetails();
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Delivery details deleted successfully",
        life: 3000,
      });
    } catch (err) {
      console.error("Error deleting delivery details", err);
    }
  };

  const handleOpen = (row) => {
    dispatch(updatedeliveryEdit(true));
    dispatch(updateDeliveryDetail(row));
    dispatch(updatedeliveryModal(true));
  };

  return (
    <Box>
      <Toast ref={toast} />
      <ConfirmDialog />
      <TableContainer sx={{ height: "100%" }}>
        <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
          <TableHead style={{ backgroundColor: "background.upload" }}>
            <TableRow>
              {/* Table headers */}
              <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>S No</TableCell>
              <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography>Partner Name</Typography>
                  {/* <Button sx={{ color: "black", width: "11px", height: "22px" }}>
                    <ImportExportSharpIcon />
                  </Button> */}
                </Box>
              </TableCell>
              <TableCell sx={{ fontWeight: "600", textAlign: "left" }}>Contact Info</TableCell>
              <TableCell sx={{ fontWeight: "600", textAlign: "left" }}>Onboard On</TableCell>
              <TableCell sx={{ fontWeight: "600", textAlign: "left" }}>Status</TableCell>
              <TableCell sx={{ fontWeight: "600", textAlign: "left" }}>KYC Status</TableCell>
              {/* <TableCell sx={{ fontWeight: "600", textAlign: "left" }}>Assigned Orders</TableCell> */}
              <TableCell sx={{ fontWeight: "600", textAlign: "left" }}>Actions</TableCell>
            </TableRow>
          </TableHead>

          {/* <TableBody>
  {filteredDeliveryUsers.length > 0 ? (
    filteredDeliveryUsers.map((item, ind) => (
      <TableRow key={item.aadhar} sx={{ background: "white" }}>
        <TableCell sx={{ textAlign: "center" }}>{item.id}</TableCell>
        <TableCell>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
            <Box component="img" src={item.partner_image} sx={{ width: "40px", height: "40px", borderRadius: "8px", objectFit: "cover" }} />
            <Box sx={{ textAlign: "left" }}>
              <Typography sx={{ fontSize: "14px" }}>{item.username}</Typography>
              <Typography sx={{ fontSize: "12px", color: "gray" }}>DOB: {item.dob.split("-").join("/")}</Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell sx={{ textAlign: "left" }}>
          <Typography>{item.address}</Typography>
          <Typography>Mobile: {item.mobileno}</Typography>
          Email:{" "}
          <a
            href={`mailto:${item.partner_email}`}
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {item.partner_email}
          </a>
        </TableCell>

        <TableCell sx={{ textAlign: "center" }}>
          <Typography>{item.created_on.split("T")[0]}</Typography>
        </TableCell>

        <TableCell sx={{ textAlign: "center" }}>
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            {
              item.Userstatus === "active" ? (
                <Box
              sx={{
                boxShadow: "0px 0px 8px 0px #00B578",
                height: "10px",
                width: "10px",
                borderRadius: "50%",
                background: "radial-gradient(50% 50% at 50% 50%, #b5bcc1 0%, #4ADE80 100%)",
              }}
            />
              ) : (
                <Box
              sx={{
                boxShadow: "0px 0px 8px 0px #999999",
                height: "10px",
                width: "10px",
                borderRadius: "50%",
                background: "radial-gradient(50% 50% at 50% 50%, #c1bcbc 100%, #999999 100%)",
              }}
            />
              )
            }
            <Box sx={{ textAlign: "left" }}>
              <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                {item.Userstatus === "active" ? "Active" : "Inactive"}
              </Typography>
              <Typography sx={{ fontSize: "12px", color: "#6B7280" }}>
                {item.created_on}
              </Typography>
            </Box>
          </Box>
        </TableCell>

        <TableCell sx={{ textAlign: "center" }}>
          <Typography sx={{ display: "flex", gap: "8px", fontFamily: "roboto", fontSize: 14 }}>
            {item.KYCstatus === "approved" ? (
              <>
                <TaskAltIcon
                  sx={{
                    color: "#00B578",
                    width: "20px",
                    height: "20px",
                  }}
                />
                <Typography sx={{ fontFamily: "Roboto", fontSize: 14 }}> Approved </Typography>
              </>
            ) : (
              <>
                <CancelIcon
                  sx={{
                    color: "#FF3141",
                    width: "20px",
                    height: "20px",
                  }}
                />
                <Typography sx={{ fontFamily: "roboto", fontSize: 14 }}> Pending </Typography>
              </>
            )}
          </Typography>
        </TableCell>

        <TableCell sx={{ textAlign: "left" }}>
          <Tooltip title="Edit" arrow>
            <IconButton onClick={() => handleOpen(item)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" arrow>
            <IconButton onClick={() => confirmDelete(item)}>
              <DeleteOutlineIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    ))
  ) : (
    <TableRow>
      <TableCell colSpan={7} sx={{ textAlign: "center", fontStyle: "italic", color: "gray" }}>
        No user found
      </TableCell>
    </TableRow>
  )}
</TableBody> */}

<TableBody>
  {filteredDeliveryUsers.length > 0 ? (
    filteredDeliveryUsers.map((item, ind) => (
      <TableRow key={item.aadhar} sx={{ background: "white" }}>
        <TableCell sx={{ textAlign: "center" }}>{ind + 1}</TableCell>
        {/* Partner Name and Image */}
        <TableCell>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
            <Box component="img" src={item.partner_image} sx={{ width: "40px", height: "40px", borderRadius: "8px", objectFit: "cover" }} />
            <Box sx={{ textAlign: "left" }}>
              <Typography sx={{ fontSize: "14px" }}>{item.username}</Typography>
              <Typography sx={{ fontSize: "12px", color: "gray" }}>DOB: {item.dob.split("-").join("/")}</Typography>
            </Box>
          </Box>
        </TableCell>
          <TableCell sx={{ textAlign: "left" }}>
          <Typography>{item.address}</Typography>
          <Typography>Mobile: {item.mobileno}</Typography>
          Email:{" "}
          <a
            href={`mailto:${item.partner_email}`} 
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {item.partner_email}
          </a>
        </TableCell>

        <TableCell sx={{ textAlign: "left" }}>
          <Typography>{item.created_on.split("T")[0]}</Typography>
        </TableCell>

        <TableCell sx={{ textAlign: "center" }}>
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            {
              item.Userstatus === "active" ? (
                <Box
              sx={{
                boxShadow: "0px 0px 8px 0px #00B578",
                height: "10px",
                width: "10px",
                borderRadius: "50%",
                background: "radial-gradient(50% 50% at 50% 50%, #b5bcc1 0%, #4ADE80 100%)",
              }}
            />
              ) : (
                <Box
              sx={{
                boxShadow: "0px 0px 8px 0px #999999",
                height: "10px",
                width: "10px",
                borderRadius: "50%",
                background: "radial-gradient(50% 50% at 50% 50%, #c1bcbc 100%, #999999 100%)",
              }}
            />
              )
            }
            <Box sx={{ textAlign: "left" }}>
              <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                {item.Userstatus === "active" ? "Active" : "Inactive"}
              </Typography>
              <Typography sx={{ fontSize: "12px", color: "#6B7280" }}>
                {item.created_on}
              </Typography>
            </Box>
          </Box>
        </TableCell>

        <TableCell sx={{ textAlign: "center" }}>
          <Typography sx={{ display: "flex", gap: "8px", fontFamily: "roboto", fontSize: 14 }}>
            {item.KYCstatus === "approved" ? (
              <>
                <TaskAltIcon
                  sx={{
                    color: "#00B578",
                    width: "20px",
                    height: "20px",
                  }}
                />
                <Typography sx={{ fontFamily: "Roboto", fontSize: 14 }}> Approved </Typography>
              </>
            ) : (
              <>
                <CancelIcon
                  sx={{
                    color: "#FF3141",
                    width: "20px",
                    height: "20px",
                  }}
                />
                <Typography sx={{ fontFamily: "roboto", fontSize: 14 }}> Pending </Typography>
              </>
            )}
          </Typography>
        </TableCell>

        <TableCell sx={{ textAlign: "left" }}>
          <Tooltip title="Edit" arrow>
            <IconButton onClick={() => handleOpen(item)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" arrow>
            <IconButton onClick={() => confirmDelete(item)}>
              <DeleteOutlineIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    ))
  ) : (
    <TableRow>
      <TableCell colSpan={7} sx={{ textAlign: "center", fontStyle: "italic", color: "gray" }}>
        No user found
      </TableCell>
    </TableRow>
  )}   
</TableBody>


        </Table>
      </TableContainer>
    </Box>
  );
}



import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    inventory_detail: {
        product_id: '',
        total_units: '',
        units:"",
        available_units: '',
        place: '',
        supervisor_ids: [
            0
        ],
        details_id: ''
    },
    inventoryDetailsEdit: false,
    inventoryEdit: false,
    inventory: [],
    inventoryForm: {
        place: '',
        supervisor_ids: []
    },
    modal: false,
    inventoryDetailsModal: false,
    allInventryDetails: [],
    supervisors: [],
    hubsupervisors: [],
    inventoryLoading: false,
    inventoryDetailsLoading: false,
};


const inventoryReducer = createSlice({
    name: "inventoryReducer",
    initialState: initialState,
    reducers: {
        updateInventoryDetail: (state, { type, payload }) => {
            state.inventory_detail = payload
        },
        updateInventory: (state, { type, payload }) => {
            console.log(payload)
            state.inventory = payload
        },
        updateInventoryModal: (state, { type, payload }) => {
            state.modal = payload
        },
        updateinventoryDetailsEdit: (state, { payload }) => {
            state.inventoryDetailsEdit = payload
        },
        updateallInventryDetails: (state, { payload }) => {
            state.allInventryDetails = payload
        },
        updateInventrySupervisors: (state, { payload }) => {
            state.supervisors = payload
        },
        updateInventryHubsupervisors: (state, { payload }) => {
            state.hubsupervisors = payload
        },
        updateInventoryDetailsModal: (state, { type, payload }) => {
            state.inventoryDetailsModal = payload
        },
        updateInventoryform: (state, { type, payload }) => {
            state.inventoryForm = payload
        },
        updateinventoryEdit: (state, { payload }) => {
            state.inventoryEdit = payload
        },
        updateInventoryLoading: (state, { payload }) => {
            state.inventoryLoading = payload
        },
        updateInventoryDetailsLoading: (state, { payload }) => {
            state.inventoryDetailsLoading = payload
        },
    }
})

export const { updateInventory, updateInventoryModal, updateInventoryDetail, updateEdit, updateallInventryDetails, updateinventoryDetailsEdit, updateInventryHubsupervisors, updateInventrySupervisors, updateInventoryDetailsModal, updateInventoryform, updateinventoryEdit ,updateInventoryLoading ,updateInventoryDetailsLoading} = inventoryReducer.actions



export default inventoryReducer.reducer;
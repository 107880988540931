import { Avatar, Button, IconButton, Popover, Typography } from "@mui/material";
import { Box } from "@mui/system";
import logo from "../Assets/SmartKart_Logo_Final_SmartKart_Color.png"
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {  Menu, MenuItem } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { Badge } from 'primereact/badge';
import { updateOrderDetails, updateOrderModel, updateselectedDeliveryPartner } from "../Redux/orders";

export function Header() {
    const first_name = useSelector(state => state.user.first_name)
    const last_name = useSelector(state => state.user.last_name)
    const dispatch = useDispatch();
    const pending = useSelector(state => state.orders.pendingOrders)
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElNotify, setAnchorElNotify] = useState(null);
    const socketStatus = useSelector(state => state.page.socketStatus)
    const navigate = useNavigate()
    const handleClick = (event) => {
        setAnchorElNotify(event.currentTarget);
      };
  
      const handleCloseNotify = () => {
        setAnchorElNotify(null);
      };
    const open = Boolean(anchorElNotify);
    const id = open ? 'simple-popover' : undefined;
    const capitalize = (str) => {
        return str?.split(' ').map(word => word?.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    };

    function stringToColor(string) {
        let hash = 0;
        let i;

        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        return color;
    }

    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    const handleClose = () => {
        setAnchorEl(null);  
      };

      const logout=()=>{
        localStorage.clear() 
        navigate("/")
    }

    const handleModalChange = (item) => {
        console.log(item)
        handleCloseNotify()
        dispatch(updateOrderModel(true))
        dispatch(updateOrderDetails(item))
        if(item.delivery_partner_id){
            dispatch(updateselectedDeliveryPartner(item.delivery_partner_id))
        }else{
            dispatch(updateselectedDeliveryPartner(""))
        }
      };
    return (<Box sx={{ width: "100%",  display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "background.light" }}>
        <Box sx={{display:"flex",gap:"12px",alignItems:"flex-end",padding:"20px"}}>
            <Box component="img" sx={{width:"38px",height:"32px"}} src={logo} />
            <Box sx={{ display: "flex", flexDirection: "column",gap:"8px" }}>
                <Typography sx={{lineHeight:"10px",fontSize:"18px",fontWeight:"600"}}>SMARTKART</Typography>
                <Typography sx={{lineHeight:"10px",fontSize:"18px",fontWeight:"600"}}>CONTROL HUB {socketStatus?<Badge style={{width:"15px",height:"15px"}} severity="success"></Badge>:<Badge style={{width:"15px",height:"15px"}} severity="danger"></Badge>}</Typography>
            </Box>
        </Box>
        <Box sx={{width:"100%",maxWidth:"100px",display:"flex",justifyContent:"flex-end",gap:"14px",alignItems:"center",padding:"20px",paddingRight:{xs:"60px"}}}>
            <IconButton disabled={pending.length>0?false:true} aria-describedby={id} onClick={handleClick}>
            <i className="pi pi-bell p-overlay-badge" style={{ fontSize: '20px' }}>
                {pending.length>0&&<Badge value={pending.length}></Badge>}
            </i>
            </IconButton>
                <Box sx={{width:"100%",maxWidth:"500px",display:{xs:"none",md:"block",lg:"block"},justifyContent:"flex-end",gap:"24px",alignItems:"center"}}>
                      <Avatar {...stringAvatar(capitalize(first_name) + " " + capitalize(last_name))} onClick={(event) => setAnchorEl(event.currentTarget)} style={{ height:"32px",width:"32px",borderRadius:"16px",bgcolor:"#3B82F6",fontSize:"14px",fontWeight:"700",cursor: "pointer"}} />
                </Box>
        </Box>
        <Menu
        id="simple-menu"
        anchorEl={anchorEl} 
        keepMounted
        open={Boolean(anchorEl)} 
        onClose={handleClose}  
        sx={{padding:0}}
      >
        <MenuItem sx={{backgroundColor: 'white',padding:"5px 15px"}} onClick={() =>logout()}>Logout</MenuItem>
      </Menu>
            
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorElNotify}
                    onClose={handleCloseNotify}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    {pending?.length>0&&pending?.map((item)=>(
                    <Box sx={{width:{sx:"100%",md:"400px"},height:"100%",minHeight:"100px",backgroundColor: 'white',border:"1 px",borderColor:"secondary.border",padding:"10px"}}>
                        <Box sx={{padding:"10px ",backgroundColor:"background.default",borderRadius:"5px"}}>
                            <Typography sx={{fontWeight:500 }}>Order Id: {item.order_id}</Typography>
                        <Box sx={{display:"flex",gap:"10px",justifyContent:"space-between"}}>
                            <Box>
                                <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22px" }}>{item?.delivery_address?.door_flat_no} ,{item?.delivery_address?.address}</Typography>
                            </Box>
                            <Button variant="contained" sx={{height:"32px"}} onClick={()=> handleModalChange(item)}>View</Button>
                        </Box>
                        </Box>
                        
                    </Box>
                    ))}
                  </Popover>
                
    </Box>)
}
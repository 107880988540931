import { Box, Button, InputBase, Typography } from "@mui/material";
import logo from "../Assets/logo.svg"
import { useState } from "react";
import axios from "axios";
import { MuiOtpInput } from 'mui-one-time-password-input'
import { useDispatch, useSelector } from "react-redux";
import { updateMobileno } from "../Redux/user";
import { useNavigate } from "react-router-dom";
import CustomAxios from "../utils/CustomAxios";

export function Signup() {
    const [registration, setRegistration] = useState('')
    const [otp, setOtp] = useState('')
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        mobileno: '',
        password: '',
    });
    const mobileno = useSelector(state => state.user.mobileno)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await CustomAxios.post(`signup/`, formData).then(res => {
            console.log(res);
            if (res.status == 201) {
                setRegistration('success')
                dispatch(updateMobileno(res.data.user.mobileno))
                navigate("/dashboard");
            } else {
                setRegistration('success')
            }
        })
        console.log(formData);
    };

    const handleSubmitOtp = async (e) => {
        const payload = {
            mobileno: mobileno,
            otp: otp
        }
        e.preventDefault();
        const response = await CustomAxios.post(`otp-verification/`, payload).then(res => {
            console.log(res);
            if (res.data.message == "Congratulations, your account has been successfully verified") {
                navigate("/")
            }else {
                console.log("failed to login")
            }
        })
    };
    console.log(mobileno)
    return (
        <Box sx={{ backgroundImage: 'linear-gradient(to bottom right, #FFF 50%, #ED1E79 50%)', height: "100vh", width: "100%", opacity: 0.7, display: "flex", justifyContent: "center", alignItems: "center", position: "relative" }}>
            <Box component="img" src={logo} sx={{ position: "absolute", top: "20px", left: "20px" }}></Box>

            <Box sx={{ width: "50%", height: "70%", backgroundColor: "white", boxShadow: "rgba(0, 0, 0, 0.56) 0px 22px 70px 4px", borderRadius: "16px", display: "flex" }}>
                <Box sx={{ width: "50%", backgroundColor: "primary.main", borderRadius: "16px", display: "flex", flexDirection: "column", gap: "10px", justifyContent: "center", alignItems: "center", padding: "20px" }}>
                    <Typography sx={{ color: "white", fontWeight: "500", textAlign: "center", width: "100%",fontSize:"26px" }}>Sign up</Typography>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                        <Typography sx={{ color: "white", fontWeight: "500", textAlign: "left", width: "100%" }}>First name</Typography>
                        <InputBase name="first_name" sx={{ backgroundColor: "#fff", height: "36px", borderRadius: "5px", padding: "12px 8px" }} placeholder="Enter first name" onChange={handleChange} />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                        <Typography sx={{ color: "white", fontWeight: "500", textAlign: "left", width: "100%" }}>Last name</Typography>
                        <InputBase name="last_name" sx={{ backgroundColor: "#fff", height: "36px", borderRadius: "5px", padding: "12px 8px" }} placeholder="Enter last name" onChange={handleChange} />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                        <Typography sx={{ color: "white", fontWeight: "500", textAlign: "left", width: "100%" }}>Email</Typography>
                        <InputBase name="email" sx={{ backgroundColor: "#fff", height: "36px", borderRadius: "5px", padding: "12px 8px" }} placeholder="Enter your Email" onChange={handleChange} />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                        <Typography sx={{ color: "white", fontWeight: "500", textAlign: "left", width: "100%" }}>Mobile Number</Typography>
                        <InputBase name="mobileno" sx={{ backgroundColor: "#fff", height: "36px", borderRadius: "5px", padding: "12px 8px" }} placeholder="Enter your mobile number" onChange={handleChange} />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                        <Typography sx={{ color: "white", fontWeight: "500", textAlign: "left", width: "100%" }}>Password</Typography>
                        <InputBase type="password" name="password" sx={{ backgroundColor: "#fff", height: "36px", borderRadius: "5px", padding: "12px 8px" }} placeholder="Enter password" onChange={handleChange} />
                    </Box>
                    <Button variant="outlined" onClick={handleSubmit} sx={{ backgroundColor: "white", color: "primary.main", width: "40%", marginTop: "20px" }}>Submit</Button>
                </Box>
                {registration == "" ? <Box sx={{ width: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Box component="img" src={logo} sx={{ width: "350px", }}></Box>
                </Box> :
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "5px", justifyContent: "center", alignItems: "center", width: "50%",padding:"20px" }}>
                        <Typography sx={{ fontSize: "24px", fontWeight: "600", width: "100%", textAlign: "center" }}>OTP Verification</Typography>
                        <MuiOtpInput gap={1} sx={{padding:"5px"}} value={otp} length={6} onChange={(value) => setOtp(value)} />
                        <Button variant="outlined" onClick={handleSubmitOtp} sx={{ backgroundColor: "white", color: "primary.main", width: "40%", marginTop: "20px" }}>Verify</Button>
                    </Box>}
            </Box>
        </Box>
    )
}
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  deliverydetail: {
    first_name: "",
    last_name: "",
    email: "",
    mobileno: "",
    password: "",
    id: "",
    pan: "",
    aadhar: "",
    driving_licence: "",
    image: "",
    username : ""
  },
  edit: false,
  delivery: [],
  modal: false,
};

const deliveryReducer = createSlice({
  name: "deliveryReducer",
  initialState: initialState,
  reducers: {
    updateDeliveryDetail: (state, { type, payload }) => {
      state.deliverydetail = payload;
    },
    updateDelivery: (state, { type, payload }) => {
      state.delivery = payload;
    },
    updateModal: (state, { type, payload }) => {
      state.modal = payload;
    },
    updateEdit: (state, { payload }) => {
      state.edit = payload;
    },
  },
});

export const { updateDelivery, updateModal, updateDeliveryDetail, updateEdit } =
  deliveryReducer.actions;

export default deliveryReducer.reducer;

import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    first_name: '',
    last_name: '',
    email: '',
    mobileno: '',
    password: '',
    id:'',
};


const userReducer = createSlice({
    name: "userReducer",
    initialState: initialState,
    reducers: {
        updateFirst_name: (state, {  payload }) => {
            state.first_name = payload
        },
        updateLast_name: (state, {  payload }) => {
            state.last_name = payload
        },
        updateEmail: (state, {  payload }) => {
            state.email = payload
        },
        updateMobileno: (state, {  payload }) => {
            state.mobileno = payload
        },
        updateId: (state, {  payload }) => {
            state.id = payload
        },
    }
    })

    export const { updateFirst_name,updateLast_name,updateEmail,updateMobileno,updateId} = userReducer.actions
    
    export default userReducer.reducer;
import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    pageName:"product_details",
    dp_pageName:"order_management",
    searchProduct:"",
    searchOrders:"",
    searchProduct:"",
    searchDelivery : "",
    socketStatus : false,
};


const pageReducer = createSlice({
    name: "pageReducer",
    initialState: initialState,
    reducers: {
        updatepageName: (state, { type, payload }) => {
            console.log(payload)
            state.pageName = payload
        },
        updateDPpageName: (state, { type, payload }) => {
            console.log(payload)
            state.dp_pageName = payload
        },
        updatesearchProduct: (state, { type, payload }) => {
            state.searchProduct = payload
        },
        updateSearchOrders: (state, { type, payload }) => {
            state.searchOrders = payload
        },
        updateSearchDeliverypartner : (state, { type, payload }) => {
            state.searchDelivery = payload
        },
        updatesocketStatus : (state, { type, payload }) => {
            state.socketStatus = payload
        }
    }

    })

    export const { updatepageName,updatesearchProduct, updateDPpageName,updateSearchOrders, updateSearchDeliverypartner ,updatesocketStatus} = pageReducer.actions
    
    
    
    export default pageReducer.reducer;
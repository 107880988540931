import * as React from 'react';
import { Box, Button, Typography,IconButton, Menu, MenuItem } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import dashboard from "../Assets/dash/Dashboard.png"
import delivery from "../Assets/dash/delivery.svg"
import order from "../Assets/dash/order.svg"
import product from "../Assets/dash/product.svg"
import user from "../Assets/dash/user.svg"
import { useDispatch, useSelector } from "react-redux";
import { updateDPpageName } from "../Redux/page";
import { useRef } from "react";
import { Menu as PrimeMenu } from 'primereact/menu';
import 'primereact/resources/themes/saga-blue/theme.css'; // Theme CSS
import 'primereact/resources/primereact.min.css'; // PrimeReact core CSS
import 'primeicons/primeicons.css';
import { useNavigate } from 'react-router-dom';
export function Sidebar() {
    const dispatch = useDispatch()
    const editMenu = useRef(null);
    const pagename = useSelector(state => state.page.pageName)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuOpen = Boolean(anchorEl);
    const navigate = useNavigate()
    const editItems = [
        {
            label: 'Product Details',
            command: () => dispatch(updateDPpageName("product_details"))
        },
        {
            label: 'Inventory',
            command: () => dispatch(updateDPpageName("inventory"))
        },
        // {
        //     label: 'Campaign',
        //     command: () => dispatch(updateDPpageName("campaign"))
        // }
    ];

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    console.log(editItems)
    const logout=()=>{
        localStorage.clear() 
        navigate("/shino-partner")
    }
    return (
    <Box sx={{ display: 'block', position: {xs:"absolute", md:"initial"}, top: {xs:16, md:"initial"}, right: {xs:20, md:"initial"} }}>
        <Box
            sx={{
            display: { xs: 'none', md: 'flex' },
            width: "84px",
            height: "710px",
            padding: "24px 8px",
            backgroundColor: "background.light",
            marginLeft: "20px",
            marginTop: "20px",
            borderRadius: "16px",
            boxShadow: "0px 0px 25px 0px #0000001A",
            flexDirection: "column",
            gap: "18px",
            position: "sticky",
            top: "24px"
            }}
        >
            {/* <Box sx={{ display: "flex", order_managementflexDirection: "column", gap: "18px" }}> */}
                {/* <Button sx={{ display: "flex", flexDirection: "column", gap: "2px" }} onClick={() =>  dispatch(updateDPpageName("dashboard"))}><Box component="img" src={dashboard} sx={{ color: "green" }}></Box><Typography sx={{ fontSize: "9px", color: "text.secondary", fontWeight: "400", lineHeight: "12.6px", textAlign: "center",color:pagename=="dashboard"?"primary.main":"text.secondary" }}>Dashboard</Typography></Button> */}
                {/* <Box className="productManagement" sx={{position:"relative"}}>
                    <PrimeMenu model={editItems} popup ref={editMenu} style={{marginLeft:"78px",marginTop:"-70px",padding:0 ,fontSize:"14px",fontWeight:400,lineHeight:"22px"}} className="custom-menu"/>
                    <Button sx={{ display: "flex", flexDirection: "column", gap: "2px" }} onClick={(event) => {editMenu.current.toggle(event) }}><Box component="img" src={product}></Box><Typography sx={{ fontSize: "9px", color: "text.secondary", fontWeight: "400", lineHeight: "12.6px", textAlign: "center" ,color:pagename=="product_details"||pagename=="inventory"||pagename=="campaign"?"primary.main":"text.secondary"}}>Product Management</Typography></Button>
                </Box> */}
                <Button sx={{ display: "flex", flexDirection: "column", gap: "2px" }} onClick={() => dispatch(updateDPpageName("order_management"))}><Box component="img" src={order}></Box><Typography sx={{ fontSize: "9px", color: "text.secondary", fontWeight: "400", lineHeight: "12.6px", textAlign: "center",color:pagename=="order_management"?"primary.main":"text.secondary" }}>Order Management</Typography></Button>
                <Button sx={{ display: "flex", flexDirection: "column", gap: "2px" }} onClick={() => dispatch(updateDPpageName("dp-earnings"))}><Box component="img" src={delivery}></Box><Typography sx={{ fontSize: "9px", color: "text.secondary", fontWeight: "400", lineHeight: "12.6px", textAlign: "center",color:pagename=="delivery_management"?"primary.main":"text.secondary" }}>Earnings</Typography></Button>
                {/* <Button sx={{ display: "flex", flexDirection: "column", gap: "2px" }} onClick={() => dispatch(updateDPpageName("user_management"))}><Box component="img" src={user}></Box><Typography sx={{ fontSize: "9px", color: "text.secondary", fontWeight: "400", lineHeight: "12.6px", textAlign: "center",color:pagename=="user_management"?"primary.main":"text.secondary" }}>User Management</Typography></Button> */}
            {/* </Box> */}
        </Box>
        {/* Mobile Hamburger Menu */}
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <IconButton
                id="compact-menu-button"
                aria-controls={menuOpen ? 'compact-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={menuOpen ? 'true' : undefined}
                onClick={handleClick}
                color="inherit"
            >
                <MenuIcon />
            </IconButton>

            <Menu
                id="compact-menu"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'compact-menu-button',
                }}
            >
                <Box>
                    <MenuItem onClick={() => { dispatch(updateDPpageName("order_management")); handleClose(); }}>Order Management</MenuItem>
                    <MenuItem onClick={() => { dispatch(updateDPpageName("dp-earnings")); handleClose(); }}>Earnings</MenuItem>
                    <MenuItem onClick={() =>logout()}>Logout</MenuItem>
                </Box>
            </Menu>
        </Box>
    </Box>
    )
}
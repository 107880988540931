import { configureStore, combineReducers } from "@reduxjs/toolkit";
import pageReducer from "./page"
import userReducer from "./user"
import productsReducer from "./products"
import inventoryReducer from "./inventory"
import deliveryReducer from "./delivery";
import orderReducer from "./orders"
import deliveryPartner from "./delivery-partner";
import deliveryUser from "./deliveryUser";

let rootReducer = combineReducers({
    page: pageReducer,
    user: userReducer,
    products: productsReducer,
    inventory: inventoryReducer,
    orders: orderReducer,
    deliveryPartner:deliveryPartner,
    deliveryUser : deliveryUser
  });

let store = configureStore({
    reducer:rootReducer,
});

export default store;
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  deliverydetail: {
    id: "",
    username: "",
    dob: "",
    mobileno: "",
    partner_email: "",
    pan: "",
    aadhar: "",
    address : "",
    driving_licence: "",
    partner_image: [],
    document_img: [], 
    vehicle_type : "",
    vehicle_number : "",
    vehicle_insurance : "",
    KYCstatus : "",
    UserStatus : ""
  },
  delivery: [],
  edit: false,
  modal: false,

};


// console.log("redux from the modal ---------------------", );

const deliveryReducer = createSlice({
  name: "deliveryReducer",
  initialState: initialState,
  reducers: {
    updateDeliveryDetail: (state, { payload }) => {
      state.deliverydetail = payload;
    },
    updatedeliveryModal: (state, { payload }) => {
        console.log(
          "Reducer updatedeliveryModal called with payload: ",
          payload
        );

      state.modal = payload;
    },
    updatedeliveryEdit: (state, { payload }) => {
      state.edit = payload;
    },
    updateDelivery : (state, { payload }) => {
      state.delivery = payload
  },
}
});

export const { updateDeliveryDetail, updateDelivery ,updatedeliveryModal, updatedeliveryEdit } =
  deliveryReducer.actions;

export default deliveryReducer.reducer;
import {
  Box,
  Button,
  InputBase,
  Modal,
  Typography,
  Input,
  IconButton,
  Tooltip,
  colors,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateSearchDeliverypartner } from "../../Redux/page";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import "../../App.css";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { isAxiosError } from 'axios'
import { Toast } from "primereact/toast";
import { useRef } from "react";
import * as Yup from "yup";
import FilterListIcon from "@mui/icons-material/FilterList";
import { DeliveryTable } from "../../sub_components/delivery_table";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  updatedeliveryModal,
  updatedeliveryEdit,
  updateDeliveryDetail,
  updateDelivery,
} from "../../Redux/deliveryUser";
import dayjs from "dayjs";
import CustomAxios from "../../utils/CustomAxios";
import CustomAxiosDP from "../../utils/CustomAxiosDP";

export function DeliveryManagement() {

  const dispatch = useDispatch();
  const toast = useRef(null);
  const authToken = localStorage.getItem("authtoken");
  const modal = useSelector((state) => state.deliveryUser.modal);
  const edit = useSelector((state) => state.deliveryUser.edit);
  const [dob, setDob] = useState(dayjs());
  const deliveryDetails = useSelector(
    (state) => state.deliveryUser.deliverydetail
  );
  const [imagePreviews, setImagePreviews] = useState([]);
  const [docimagePreviews, setdocimagePreviews] = useState([]);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState([]);

  // const [selectedDate, setSelectedDate] = useState(null);
  // const [error, setError] = useState("");
const UserStatus = [
  { name: "Active", value: "active" },
  { name: "Inactive", value: "inactive" }, // Corrected to "inactive"
];

const KYCStatus = [
  { name: "Pending", value: "pending" },
  { name: "Approved", value: "approved" },
];

const vehicleType = [
  { name: "Motor Cycle", value: "motorcycle" }, 
  { name: "Cycle", value: "cycle" },
];

const vehicleInsurance = [
  { name: "No", value: "no" },
  { name: "Yes", value: "yes" },
];

//   const generatePassword = () => {
//   const prefix = "AIQ65";
//   const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
//   let password = prefix;

//   // Generate 3 random characters to make the length 8
//   for (let i = 0; i < 3; i++) {
//     const randomIndex = Math.floor(Math.random() * characters.length);
//     password += characters[randomIndex];
//   }

//   return password;
// };




  useEffect(() => {
    setFormData(deliveryDetails);
  }, [deliveryDetails]);



// useEffect(() => {
//   if (edit && deliveryDetails) {
//     // const token = "sp=r&st=2024-11-05T13:01:49Z&se=2024-11-05T21:01:49Z&sv=2022-11-02&sr=c&sig=8zG3m0cv%2F41eqo8a8QL7qA%2BDPn%2BYbuLCn9svWpXgmBo%3D"
//     // Fetch and set the partner_image
//     const fetchPartnerImage = async () => {
//       if (deliveryDetails.partner_image) {
//         const response = await fetch(deliveryDetails.partner_image)
//         //   , {
//         //    headers: {
//         //   'Authorization': `Bearer ${token}`
//         // }
//         ;
//         const blob = await response.blob();
//         const fileName = deliveryDetails.partner_image.split("/").pop();
//         const partnerImageFile = new File([blob], fileName, {
//           type: "image/jpeg",
//         });

//         // Combine state updates
//         setFormData((prevData) => ({
//           ...prevData,
//           partner_image: partnerImageFile,
//           ...deliveryDetails, // include other deliveryDetails here
//         }));

//         // Set the image preview for the UI
//         setImagePreviews([deliveryDetails.partner_image]);
//       }
//     };

//     // Fetch and set document images
//     const fetchDocumentImages = async () => {
//       const fetchedImages = await Promise.all(
//         (deliveryDetails.document_img || []).map(async (url) => {
//           const response = await fetch(url);
//           const blob = await response.blob();
//           const fileName = url.split("/").pop();
//           return new File([blob], fileName, { type: "image/jpeg" });
//         })
//       );

//       // Log the fetched images
//       console.log("Fetched images:", fetchedImages);

//       // Combine state updates
//       setFormData((prevData) => ({
//         ...prevData,
//         document_img: fetchedImages,
//       }));

//       // Set document image previews
//       setdocimagePreviews(fetchedImages.map(img => URL.createObjectURL(img)));
//     };

//     // Run the asynchronous fetch operations
//     fetchPartnerImage();
//     fetchDocumentImages();
//   }
// }, [edit, deliveryDetails]);

useEffect(() => {
  if (edit && deliveryDetails) {
    // Fetch and set the partner_image as a file
    const fetchPartnerImage = async () => {
      if (deliveryDetails.partner_image) {
        try {
          // Fetch the image as a blob
          const response = await fetch(deliveryDetails.partner_image);
          const blob = await response.blob();
          const fileName = deliveryDetails.partner_image.split("/").pop();
          
          // Convert the blob to a File object
          const partnerImageFile = new File([blob], fileName, {
            type: "image/jpeg", // Assuming the image type is jpeg
          });

          // Combine state updates, setting partner_image as a File
          setFormData((prevData) => ({
            ...prevData,
            partner_image: partnerImageFile, // Update partner_image as a File
            ...deliveryDetails, // Include other delivery details here
          }));

          // Set the image preview for UI (convert File to Object URL)
          setImagePreviews([URL.createObjectURL(partnerImageFile)]);
        } catch (error) {
          console.error("Error fetching partner image:", error);
        }
      }
    };

    // Fetch and set document images as files
    const fetchDocumentImages = async () => {
      try {
        const fetchedImages = await Promise.all(
          (deliveryDetails.document_img || []).map(async (url) => {
            const response = await fetch(url);
            const blob = await response.blob();
            const fileName = url.split("/").pop();
            return new File([blob], fileName, { type: "image/jpeg" });
          })
        );

        // Log the fetched images
        console.log("Fetched document images:", fetchedImages);

        // Update form data for document images
        setFormData((prevData) => ({
          ...prevData,
          document_img: fetchedImages,
        }));

        // Set document image previews
        setdocimagePreviews(fetchedImages.map(img => URL.createObjectURL(img)));
      } catch (error) {
        console.error("Error fetching document images:", error);
      }
    };

    // Run the asynchronous fetch operations
    fetchPartnerImage();
    fetchDocumentImages();
  }
}, [edit, deliveryDetails]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    
    
    if (name === "driving_licence") {
      // Remove all non-alphanumeric characters
      const sanitizedValue = value.replace(/[^A-Za-z0-9]/g, "").toUpperCase();

      // Format the input as required
      let formattedValue = sanitizedValue;

      if (sanitizedValue.length > 4) {
        formattedValue =
          sanitizedValue.substring(0, 2) +
          sanitizedValue.substring(2, 4) + // first two letters, next two numbers
          " " +
          sanitizedValue.substring(4, 8) + // space after two numbers
          sanitizedValue.substring(8, 19); // 11 digits
      } else if (sanitizedValue.length > 2) {
        formattedValue =
          sanitizedValue.substring(0, 2) + sanitizedValue.substring(2); // first two letters and remaining
      }

      if (formattedValue.length <= 17) {
        setFormData({
          ...formData,
          [name]: formattedValue,
        });
      }
    } else if (name === "pan") {
      // Format PAN input to uppercase and restrict length to 10 characters
      const formattedValue = value
        .toUpperCase()
        .replace(/[^A-Z0-9]/g, "")
        .slice(0, 10);
      setFormData({
        ...formData,
        [name]: formattedValue,
      });
    } else if (name === "mobileno" || name === "aadhar") {
      // Ensure only numbers are allowed and restrict length
      const numericValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters

      if (name === "mobileno" && numericValue.length <= 10) {
        setFormData({
          ...formData,
          [name]: numericValue,
        });
      } else if (name === "aadhar" && numericValue.length <= 12) {
        setFormData({
          ...formData,
          [name]: numericValue,
        });
      }
    } else if (name === "partner_email") {
      // Remove spaces from the email input
      const trimmedValue = value.replace(/\s/g, ""); // Remove all spaces
      setFormData({
        ...formData,
        [name]: trimmedValue,
      });
    }
    else if (name === "vehicle_number") {
        const sanitizedValue = value.replace(/[^A-Za-z0-9]/g, "").toUpperCase();
        setFormData({
          ...formData,
          [name]: sanitizedValue,
        });
    }
    else {
      // For all other inputs, just update the form data
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };


  const getProducts = async () => {
    const response = await CustomAxiosDP
      .get(`delivery/user-details/`, {
        // headers: {
        //   Accept: "application/json",
        //   Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Imh1bGtjaGFybGllMTdAZ21haWwuY29tIiwiaWQiOiJBSVE2NUMxNDlBNjY2IiwiZmlyc3RfbmFtZSI6IlN1cnlhIiwiZXhwIjoxNzI5MzU0MTA1LCJpYXQiOjE3MjkzNTA1MDV9.P-KyTyUxd4JUDcEWXx3WPaEFyPjFmhknXT6rCTN1AsA`,
        // },
      })
      .then((res) => {
        setFormData([]);
        dispatch(updateDelivery(res.data));
      });
  };



  const handleOpen = () => {
    setErrors({});
    setDob(null)
    dispatch(
      updateDeliveryDetail({
        username: "",
        dob: dob,
        mobileno: "",
        partner_email: "",
        pan: "",
        aadhar: "",
        address: "",
        driving_licence: "",
        partner_image: "",
        document_img: [],
        vehicle_type : "",
        vehicle_number : "",
        vehicle_insurance : "",
      KYCstatus : "",
        Userstatus : ""
      })
    );
    setImagePreviews([]);
    setdocimagePreviews([]);
    dispatch(updatedeliveryEdit(false));
    dispatch(updatedeliveryModal(true));
  };



  

  const handleClose = () => {
    setErrors({});
    dispatch(updatedeliveryModal(false));
    dispatch(updatedeliveryEdit(false));
    dispatch(
      updateDeliveryDetail({
        username: "",
        dob: "",
        mobileno: "",
        partner_email: "",
        pan: "",
        aadhar: "",
        address: "",
        driving_licence: "",
        partner_image: [],
        document_img: [],
        vehicle_type : "",
        vehicle_number : "",
        vehicle_insurance : "",
        KYCstatus : "",
        Userstatus : ""
      })
    );
    setImagePreviews([]);
    setdocimagePreviews([])
  };

  console.log("formData dl length -----------------------", formData);

  const handleImageChange = (e) => {
  const { files } = e.target;
  if (!files || files.length === 0) return;

  const singleImageFile = files[0];
  setFormData((prevData) => ({
    ...prevData,
    partner_image: singleImageFile,
  }));
  // const urls = files.map((file) => URL.createObjectURL(file));
  setImagePreviews([URL.createObjectURL(singleImageFile)]);
  // setImagePreviews((prev) => [...prev, ...urls]);
};


const handleRemoveImage = (index, type) => {
  console.log("Removing image at index:", index, "of type:", type);

  if (type === "partner") {
    // Remove the partner image preview
    setImagePreviews((prev) => {
      const newImagePreviews = prev.filter((_, i) => i !== index);
      console.log("Updated partner image previews:", newImagePreviews);
      return newImagePreviews;
    });

    // Clear the partner_image field from formData
    setFormData((prevData) => ({
      ...prevData,
      partner_image: null, // Clear the partner image
    }));
  } else if (type === "document") {
    // Remove the document image preview
    setdocimagePreviews((prev) => {
      const newDocImagePreviews = prev.filter((_, i) => i !== index);
      console.log("Updated document image previews:", newDocImagePreviews);
      return newDocImagePreviews;
    });

    // Update the document_img array to exclude the removed image
    setFormData((prevData) => ({
      ...prevData,
      document_img: prevData.document_img.filter((_, i) => i !== index),
    }));
  }
};


// working code but it not working images

// const handleDocImageChange = (e) => {
  
//   const files = Array.from(e.target.files);
  
//   if(files) alert("document image added")
//   if (files.length === 0) return;

//   // Store File objects instead of file names
//   setFormData((prevData) => ({
//     ...prevData,
//     document_img: [...(prevData.document_img || []), ...files], // Append new files
//   }));

//   // Generate preview URLs for the images
//   const urls = files.map((file) => URL.createObjectURL(file));
//   setdocimagePreviews((prev) => [...prev, ...urls]);
// };
// ------------------------- working in UI not store in the db ------------------------
// const handleDocImageChange = (e) => {
//   const files = Array.from(e.target.files);

//   if (files.length === 0) return;

//   // Store File objects instead of file names
//   setFormData((prevData) => ({
//     ...prevData,
//     document_img: [...(prevData.document_img || []), ...files], // Append new files
//   }));

//   // Generate preview URLs for the images
//   const urls = files.map((file) => URL.createObjectURL(file));
//   setdocimagePreviews((prev) => [...prev, ...urls]);

//   // Reset the input value to allow re-selection of the same file
//   e.target.value = ""; // This resets the file input without changing your code structure
// };

// ------------------------

console.log("---------------- prev images both -------------", docimagePreviews, imagePreviews)

// working fine 
// const handleSaveDelivery = async () => {
//   const data = new FormData();

//   // Populate FormData with non-file fields
//   for (const [key, value] of Object.entries(formData)) {
//     if (key !== "partner_image" && key !== "document_img") {
//       data.append(key, value);
//     }
//   }

//   // Use partner_image directly from formData
//   if (formData.partner_image) {
//     data.append("partner_image", formData.partner_image);
//     console.log("Appended partner image:", formData.partner_image.name);
//   } else {
//     console.warn("No partner image file found.");
//   }
 
//   // Add document images if present
//   const docFileInput = document.querySelector(`input[type='file'][name='document_img']`);
//   if (docFileInput?.files) {
//     Array.from(docFileInput.files).forEach((file) => {
//       if (file instanceof File) {
//         data.append("document_img", file);
//         console.log("Appended document image:", file);
//       } else {
//         console.error("Expected File object for document image, received:", file);
//       }
//     });
//   } else {
//     console.warn("No document images found.");
//   }

//   // Log FormData entries before sending
//   console.log("Form data before submission:", Array.from(data.entries()));

//   try {
//     await validationSchema.validate(formData, { abortEarly: false });
//     setErrors({});  // Clear previous errors

//     const response = await axios.post(`http://127.0.0.1:8000/delivery/signup/`, data, {
//       headers: {
//         'Content-Type': 'multipart/form-data',
//       },
//     });
//     console.log("API response:", response);

//     dispatch(updateDeliveryDetail({
//       ...formData,
//       document_img: formData.document_img,
//     }));

//     toast.current.show({
//       severity: "success",
//       summary: "Success",
//       detail: "Delivery Partner Added Successfully",
//       life: 3000,
//     });

//     getProducts();
//     dispatch(updatedeliveryModal(false));
//   } catch (error) {
//     const formErrors = {};
//     if (error.name === "ValidationError") {
//       error.inner.forEach((err) => {
//         formErrors[err.path] = err.message;
//       });
//       setErrors(formErrors);
//     } 
//     // else if (axios.isAxiosError(error)) {
//     //   toast.current.show({
//     //     severity: "error",
//     //     summary: "Error",
//     //     detail: error.response.data?.message || "An error occurred during the API call",
//     //     life: 3000,
//     //   });
//     // }
//  else if (axios.isAxiosError(error)) {
//   // Extracting error details if they exist
//   const errorData = error.response?.data;
  
//   // Check if there are error messages to display
//   if (errorData) {
//     // Loop through each error field and show messages one by one
//     Object.values(errorData).flat().forEach((message) => {
//       toast.current.show({
//         severity: "error",
//         summary: "Error",
//         detail: message,
//         life: 3000,
//       });
//     });
//   }}
//      else {
//       toast.current.show({
//         severity: "error",
//         summary: "Error",
//         detail: "An unexpected error occurred",
//         life: 3000,
//       });
//     }
//   }
// };



// ----------------------------------------------------------------

// ----------------------------------------------------------------

const handleDocImageChange = (e) => {
  const files = Array.from(e.target.files);

  if (files.length === 0) return;

  // Update form data with document images
  setFormData((prevData) => ({
    ...prevData,
    document_img: [...(prevData.document_img || []), ...files],
  }));

  // Generate and store preview URLs
  const urls = files.map((file) => URL.createObjectURL(file));
  setdocimagePreviews((prev) => [...prev, ...urls]);

  // Reset file input to allow re-selection of the same file
  e.target.value = "";
};

console.log("Current document image previews:", docimagePreviews, imagePreviews);

const handleSaveDelivery = async () => {
  const data = new FormData();

  // Append non-file form fields to FormData
  for (const [key, value] of Object.entries(formData)) {
    if (key !== "partner_image" && key !== "document_img") {
      data.append(key, value);
    }
  }

  // Append partner image if available
  if (formData.partner_image) {
    data.append("partner_image", formData.partner_image);
    console.log("Appended partner image:", formData.partner_image.name);
  } else {
    console.warn("No partner image file found.");
  }

  // Append document images from formData
  if (formData.document_img) {
    formData.document_img.forEach((file, index) => {
      if (file instanceof File) {
        data.append(`document_img[${index}]`, file);
        console.log("Appended document image:", file.name);
      }
    });
  } else {
    console.warn("No document images found.");
  }

  // Log FormData entries before sending
  console.log("Form data before submission:", Array.from(data.entries()));

  try {
    await validationSchema.validate(formData, { abortEarly: false });
    setErrors({}); // Clear previous errors

    const response = await CustomAxiosDP.post(`delivery/signup/`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log("API response:", response);

    dispatch(updateDeliveryDetail({
      ...formData,
      document_img: formData.document_img,
    }));

    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Delivery Partner Added Successfully",
      life: 3000,
    });

    getProducts();
    dispatch(updatedeliveryModal(false));
  } catch (error) {
    const formErrors = {};

    if (error.name === "ValidationError") {
      // Show validation schema errors
      error.inner.forEach((err) => {
        formErrors[err.path] = err.message;
      });
      setErrors(formErrors);
    } else if (axios.isAxiosError(error)) {
      // Show error messages from API response
      const errorData = error.response?.data;

      if (errorData) {
        Object.values(errorData).flat().forEach((message) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            life: 3000,
          });
        });
      } else {
        // Generic error for unexpected issues
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "An error occurred during the API call",
          life: 3000,
        });
      }
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An unexpected error occurred",
        life: 3000,
      });
    }
  }
};


console.log("edit the data while clicking api -----------------------------",edit);



const handleUpdateDelivery = async (id) => {
    const data = new FormData();

    // Loop through the form data fields
    for (const [key, value] of Object.entries(formData)) {
        console.log("From formData:", key);

        // Handle document_img (array of files)
        if (key === "document_img" && Array.isArray(value)) {
            value.forEach((file) => {
                if (file instanceof File) {
                    console.log("Appending document_img file:", file);
                    data.append(key, file);
                } else {
                    console.error(`Expected a File object for ${key}, but received:`, file);
                }
            });
        }
        // Handle partner_image (either a single file or an array of files)
        else if (key === "partner_image") {
            if (value instanceof File) {
                console.log("Appending partner_image file:", value);
                data.append(key, value); // If it's a single file
            } else if (Array.isArray(value)) {
                value.forEach((file) => {
                    if (file instanceof File) {
                        console.log("Appending partner_image file:", file);
                        data.append(key, file); // If it's an array of files
                    } else {
                        console.error(`Expected a File object for ${key}, but received:`, file);
                    }
                });
            } else if (!value && formData?.partner_image) {
                // Append the old partner_image if value is not provided
                const oldPartnerImage = formData?.partner_image; // Get the old image
                if (oldPartnerImage) {
                    console.log("Appending old partner_image:", oldPartnerImage);
                    data.append(key, oldPartnerImage); // Append the old image
                }
            }
        }
        // For all other form fields
        else {
            data.append(key, value);
        }
    }

    try {
        console.log("Data inside the update:", Array.from(data.entries())); // Visualize the FormData

        // Validate the form data before sending it (assuming validationSchema is defined)
        await validationSchema.validate(formData, { abortEarly: false });

        // Set up request headers for multipart/form-data
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };

        // Send the PUT request with FormData
        const response = await CustomAxiosDP.put(
            `delivery/update/${id}/`, 
            data, 
            config
        );

        // Handle successful response
        if (response.status === 200 || response.status === 204) {
            toast.current.show({
                severity: "success",
                summary: "Success",
                detail: "Delivery Partner updated successfully",
                life: 3000,
            });
            getProducts();
            dispatch(updatedeliveryModal(false));
        }
    } catch (error) {
        // Handle validation error
        if (error.name === "ValidationError") {
            const formErrors = {};
            error.inner.forEach((err) => {
                formErrors[err.path] = err.message;
            });
            setErrors(formErrors);
        } 
        // Handle Axios error
        else if (axios.isAxiosError(error)) {
            toast.current.show({
                severity: "error",
                summary: "Error",
                detail: error.response?.data?.detail || "An error occurred during the API call.",
                life: 3000,
            });
        }
    }
};

  console.log('Over all data ------------------',formData);
  console.log("partner images-------------", imagePreviews);
    console.log("doc images-------------", docimagePreviews);



console.log(
"document _images ----------------------------------- from azure", docimagePreviews
);


const validationSchema = Yup.object().shape({
  partner_email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  mobileno: Yup.string()
    .length(10, "Mobile number must contain exactly 10 digits")
    .required("Mobile number is required"),
  pan: Yup.string()
    .matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}/, "PAN must be in the format ABCDE1234F")
    .required("PAN is required"),
  aadhar: Yup.string()
    .length(12, "Aadhar number must contain exactly 12 digits")
    .required("Aadhar is required"),
  driving_licence: Yup.string()
    .matches(/^[A-Z]{2}[0-9]{2}\s[0-9]{4}[0-9]{8}$/, "Driving Licence must be in the format TN29 202115651708")
    .required("Driving Licence is required")
    .test(
      "length",
      "Driving Licence must contain up to 12 digits after the initial format",
      (value) => {
        const digitPart = value ? value.split(" ")[1] : "";
        return digitPart.length <= 12;
      }
    ),
  // dob: Yup.date().required("Date of birth is required"),
   dob: Yup.date()
    .nullable() // allows null for Formik to handle empty fields
    .required("Date of birth is required")
    .test("is-old-enough", "You must be at least 18 years old", (value) => {
      return value && dayjs().diff(dayjs(value), 'year') >= 18;
    }),
  username: Yup.string().required("Username is required"),
  address: Yup.string()
  .matches(/^(?!\s*$).+/, "Address cannot contain only spaces")
  .required("Address is required"),
  // partner_image: Yup.mixed().required("Image is required"),
  document_img: Yup.array()
    .min(1, "At least 1 images are required")
    .required("Images are required"),
  partner_image: Yup.string()
    .min(1, "At least 1 images are required")
    .required("Images are required"),
  vehicle_type: Yup.string()
    .oneOf(vehicleType.map((v) => v.value), "Vehicle type is required"),
  vehicle_number: Yup.string()
    .transform((value) => value.toUpperCase())
    .matches(/^[A-Z]{2}[0-9]{2}[A-Z]{1,2}[0-9]{4}$/, "Vehicle number must be in the format TN09AA1234")
    .required("Vehicle number is required"),
  vehicle_insurance: Yup.string()
    .oneOf(vehicleInsurance.map((v) => v.value), "Vehicle insurance is required"),
  // password: Yup.string()
  //   .min(8, "Password must be at least 8 characters long")
  //   .required("Password is required"),
  KYCstatus: Yup.string()
    .oneOf(KYCStatus.map((k) => k.value), "KYC status must be Pending or Approved")
    .required("KYC status is required"),
  Userstatus: Yup.string()
    .oneOf(UserStatus.map((u) => u.value), "User status must be Active or Inactive")
    .required("User status is required"),
});



  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        width: "100%",
        padding : "30px"
      }}
    >
      <Toast ref={toast} />
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition="Bounce"
      />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <Typography
          sx={{ fontSize: "16px", fontWeight: "700", lineHeight: "24px" }}
        >
          Delivery Partners{" "}
        </Typography>
        <Typography
          sx={{ fontSize: "14px", fontWeight: "500", lineHeight: "22px" }}
        >
          Delivery partners{" "}
        </Typography>
      </Box>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            width: "324px",
            height: "32px",
            gap: "24px",
          }}
        >
          <InputBase
            sx={{
              backgroundColor: "white",
              height: "32px",
              width: "100%",
              maxWidth: "268px",
              borderRadius: "2px",
              border: "1px solid",
              borderColor: "secondary.border",
              padding: "5px 12px 5px 40px",
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "22px",
              color: "black",
            }}
            onChange={(e) => dispatch(updateSearchDeliverypartner(e.target.value))}
            placeholder="Search username"
          />
          <SearchIcon
            sx={{
              position: "absolute",
              left: "12px",
              top: "5px",
              width: "20px",
              height: "20px",
            }}
          />
          {/* <Button sx={{ fontWeight: "800" }}>
            <FilterListIcon />
          </Button> */}
        </Box>
        <Button
          variant="contained"
          onClick={handleOpen}
          sx={{ textTransform: "none" }}
        >
          {" "}
          Add New
        </Button>
      </Box>
      <DeliveryTable />
      <Modal
        open={modal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: 1000 }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "757px",
            // height: "687px",
            height : "836px",
            backgroundColor: "white",
            overflow : "auto"
          }}
        >
          <Box
            sx={{
              height: "46px important",
              padding: "12px 24px",
              backgroundColor: "#EEEEEE",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ fontSize: "14", fontWeight: "500", lineHeight: "22px" }}
            >
              {" "}
              {edit ? "View/Edit" : "Add New"}
            </Typography>
          </Box>
          <Box
            sx={{
              height: "340px",

            }}
          >
            <Box sx={{ margin: "1rem 3rem" }}>
              <Typography>Basic Information</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "top",
                height: "450px",
              }}
            >
    
   
 
  <Box
  sx={{
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(104px, 1fr))",
    gap: "8px",
    alignItems: "center",
    marginLeft: "5rem",
  }}
>
  {imagePreviews?.map((image, index) => (
    <Box
      key={index}
      sx={{
        width: "130px",
        height: "140px",
        border: "1px solid",
        borderColor: "secondary.border",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        cursor: "pointer",
        backgroundColor: "background.upload",
        overflow: "hidden",
        "&:hover .overlay": { display: "block" },
        "&:hover .delete-button": { display: "flex" },
        marginLeft: "-2rem",
        marginTop: "-12rem",
      }}
    >
      <img
        src={image}
        alt={`Uploaded ${index}`}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "8px",
          transition: "opacity 0.3s",
        }}
      />
      <Box
        className="overlay"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "#00000080",
          display: "none",
          borderRadius: "8px",
        }}
      />
      <Tooltip title="Delete" arrow>
        <IconButton
          className="delete-button"
          onClick={() => handleRemoveImage(index, "partner")}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            display: "none",
            transform: "translate(-50%, -50%)",
          }}
        >
          <DeleteOutlineIcon sx={{ color: "white" }} />
        </IconButton>
      </Tooltip>
    </Box>
  ))}

  {imagePreviews.length === 0 && (
    <Box
      sx={{
        width: "130px", // Match the width of the image boxes
        height: "140px", // Match the height of the image boxes
        border: "1px solid",
        borderColor: "secondary.border",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        cursor: "pointer",
        backgroundColor: "background.upload",
        marginLeft: "-2rem",
        marginTop: "-12rem",
      }}
    >
      <input
        type="file"
        accept="image/*"
        id="upload-input-image"
        style={{
          position: "absolute",
          width: "100%", // Adjusted to match parent dimensions
          height: "100%", // Adjusted to match parent dimensions
          opacity: 0,
          cursor: "pointer",
          top: 0,
          left: 0, // Align to the left
        }}
        onChange={(e) => handleImageChange(e)}
        name="partner_image"
      />
      <label
        htmlFor="upload-input-image"
        style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <AddIcon fontSize="large" />
        <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22px" }}>
          Upload
        </Typography>
      </label>
    </Box>
  )}
    {errors.partner_image && (
                      <Typography
                        style={{
                          color: "red",
                          fontSize: "12PX",
                          fontWeight: "400",
                          lineHeight: "22px",
                          marginLeft: "-2rem",
                          position:"absolute",
                          // marginTop: "1rem",
                        }}
                      >
                        {errors.partner_image}
                      </Typography>
                    )}
</Box>


              <Box
                sx={{
                  padding: "24px",
                  display: "flex",
                  gap: "16px",
                  flexDirection: "column",
                  // height: "500px",
                  // backgroundColor: "yellow",
                  overflowY: "auto",
                  width: "100%",
                  justifyContent: "center",
                  // border: "2px solid black",
                  marginTop: "-3rem",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    gap: "16px",
                  }}
                >
                  <Box
                    sx={{
                      width: "50%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14PX",
                        fontWeight: "400",
                        lineHeight: "22px",
                      }}
                    >
                      Name{" "}
                      <span style={{ color: "red", marginLeft: "5px" }}>*</span>
                    </Typography>
                    <InputBase
                      value={formData.username}
                      sx={{
                        backgroundColor: "white",
                        width: "100%",
                        height: "32px",
                        borderRadius: "2px",
                        border: "1px solid",
                        borderColor: "secondary.border",
                        padding: "5px 12px",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "22px",
                        color: "black",
                      }}
                      placeholder="Enter your name"
                      name="username"
                      onChange={handleChange}
                    />
                    {errors.username && (
                      <Typography
                        style={{
                          color: "red",
                          fontSize: "12PX",
                          fontWeight: "400",
                          lineHeight: "22px",
                        }}
                      >
                        {errors.username}
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={{
                      width: "50%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px", 
                        fontWeight: "400",
                        lineHeight: "22px",
                      }}
                    >
                      DOB
                      <span style={{ color: "red", marginLeft: "5px" }}>*</span>
                    </Typography>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          {/* <DatePicker
                              value={dob}
                              onChange={(newValue) => {
                                  setDob(newValue);
                                  setFormData({ ...formData, dob: newValue ? newValue.format('YYYY-MM-DD') : '' });
                              }}
                              maxDate={dayjs()} // Prevent future dates by setting maxDate to today
                              className="pan-datepicker background"
                              renderInput={(params) => <TextField {...params} />}
                              sx={{
                                  "&.pan-datepicker .MuiInputBase-root.MuiOutlinedInput-root": {
                                      height: '32px',
                                      padding: '0 8px',
                                      border: "none",
                                      width: "full",
                                      backgroundColor: "#FFF",
                                      zIndex: 1, 
                                  },
                                  "&.MuiPaper-root-MuiPickersPopper-paper": {
                                      backgroundColor: "#FFF",
                                  },
                                  "& .MuiPaper-root": {
                                      backgroundColor: "#FFF", // Background of the dropdown
                                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Optional: add shadow
                                      borderRadius: '8px', // Optional: rounded corners
                                  },
                                  "&.MuiDateCalendar-root.css-5wchs2-MuiDateCalendar-root" : {
                                    backgroundColor : "#fff"
                                  },
                                  background : "#fff"
                              }}
                          /> */}

                      <DatePicker
                          value={dob}
                          onChange={(newValue) => {
                              const ageLimit = dayjs().subtract(18, 'year');
                              
                              if (newValue && newValue.isAfter(ageLimit)) {
                                  // Set error message if the date is invalid (age < 18)
                                  setErrors("You must be at least 18 years old.");
                                  setDob(null);  // Reset DOB if validation fails
                                  // setFormData({ ...formData, dob: '' });
                                  setFormData({ ...formData, dob: newValue ? newValue.format('YYYY-MM-DD') : '' });

                              } else {
                                  setErrors(""); // Clear error if the date is valid
                                  setDob(newValue);
                                  setFormData({
                                      ...formData,
                                      dob: newValue.format('YYYY-MM-DD') ? dayjs(newValue).format('YYYY-MM-DD') : '' // Ensure date is in YYYY-MM-DD format
                                  });
                              }
                          }}
                          maxDate={dayjs()} // Prevent future dates
                          className="pan-datepicker background"
                          renderInput={(params) => <TextField {...params} error={!!errors} helperText={errors} />}
                          sx={{
                              "&.pan-datepicker .MuiInputBase-root.MuiOutlinedInput-root": {
                                  height: '32px',
                                  padding: '0 8px',
                                  border: "none",
                                  width: "full",
                                  backgroundColor: "#FFF",
                                  zIndex: 1, 
                              },
                              "&.MuiPaper-root-MuiPickersPopper-paper": {
                                  backgroundColor: "#FFF",
                              },
                              "& .MuiPaper-root": {
                                  backgroundColor: "#FFF",
                                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                                  borderRadius: '8px',
                              },
                              "&.MuiDateCalendar-root.css-5wchs2-MuiDateCalendar-root": {
                                  backgroundColor: "#fff",
                              },
                              background: "#fff",
                          }}
                      /> 

        </LocalizationProvider>
        

                    {errors.dob && (
                      <Typography
                        style={{
                          color: "red",
                          fontSize: "12px", // Corrected font size
                          fontWeight: "400",
                          lineHeight: "22px",
                        }}
                      >
                        {errors.dob}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box sx={{ width: "100%", display: "flex", gap: "16px" }}>
                  <Box
                    sx={{
                      width: "50%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14PX",
                        fontWeight: "400",
                        lineHeight: "22px",
                      }}
                    >
                      Email Id
                    </Typography>
                    <InputBase
                      value={formData.partner_email}
                      sx={{
                        backgroundColor: "white",
                        width: "100%",
                        height: "32px",
                        borderRadius: "2px",
                        border: "1px solid",
                        borderColor: "secondary.border",
                        padding: "5px 12px",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "22px",
                        color: "black",
                      }}
                      placeholder="Enter email"
                      name="partner_email"
                      onChange={handleChange}
                    />
                    {errors.partner_email && (
                      <Typography
                        style={{
                          color: "red",
                          fontSize: "12PX",
                          fontWeight: "400",
                          lineHeight: "22px",
                        }}
                      >
                        {errors.partner_email}
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={{
                      width: "50%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14PX",
                        fontWeight: "400",
                        lineHeight: "22px",
                      }}
                    >
                      Mobile No{" "}
                      <span style={{ color: "red", marginLeft: "5px" }}>*</span>
                    </Typography>
                    <InputBase
                      value={formData.mobileno}
                      sx={{
                        backgroundColor: "white",
                        width: "100%",
                        height: "32px",
                        borderRadius: "2px",
                        border: "1px solid",
                        borderColor: "secondary.border",
                        padding: "5px 12px",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "22px",
                        color: "black",
                      }}
                      placeholder="Enter mobileno"
                      name="mobileno"
                      onChange={handleChange}
                    />
                    {errors.mobileno && (
                      <Typography
                        style={{
                          color: "red",
                          fontSize: "12PX",
                          fontWeight: "400",
                          lineHeight: "22px",
                        }}
                      >
                        {errors.mobileno}
                      </Typography>
                    )}
                  </Box>
                </Box>
                 
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "22px",
                    }}
                  >
                    Address{" "}
                    <span style={{ color: "red", marginLeft: "5px" }}>*</span>
                  </Typography>
                  <TextField
                    value={formData.address}
                    sx={{
                      backgroundColor: "white",
                      width: "100%",
                      borderRadius: "2px",
                      borderColor: "secondary.border",
                      padding: "5px 12px",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "22px",
                      color: "black",
                    }}
                    placeholder="Enter the address"
                    name="address"
                    onChange={handleChange}
                    multiline
                    minRows={3} // Minimum number of rows
                    maxRows={5} // Maximum number of rows (optional)
                  />
                  {errors.address && (
                    <Typography
                      style={{
                        color: "red",
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "22px",
                      }}
                    >
                      {errors.address}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ height: "182px", padding: "1rem", marginTop: "6.4rem" , overflow : "auto"}}>
            <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
              Identity Proof
            </Typography>
            <Box sx={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
              <Box
                sx={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14PX",
                    fontWeight: "400",
                    lineHeight: "22px",
                  }}
                >
                  Aadhar{" "}
                  <span style={{ color: "red", marginLeft: "5px" }}>*</span>
                </Typography>
                <InputBase
                  value={formData.aadhar}
                  sx={{
                    backgroundColor: "white",
                    width: "100%",
                    height: "32px",
                    borderRadius: "2px",
                    border: "1px solid",
                    borderColor: "secondary.border",
                    padding: "5px 12px",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "22px",
                    color: "black",
                  }}
                  placeholder="Enter aadhar no"
                  name="aadhar"
                  onChange={handleChange}
                />
                {errors.aadhar && (
                  <Typography
                    style={{
                      color: "red",
                      fontSize: "12PX",
                      fontWeight: "400",
                      lineHeight: "22px",
                    }}
                  >
                    {errors.aadhar}
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14PX",
                    fontWeight: "400",
                    lineHeight: "22px",
                  }}
                >
                  PAN <span style={{ color: "red", marginLeft: "5px" }}>*</span>
                </Typography>
                <InputBase
                  value={formData.pan}
                  sx={{
                    backgroundColor: "white",
                    width: "100%",
                    height: "32px",
                    borderRadius: "2px",
                    border: "1px solid",
                    borderColor: "secondary.border",
                    padding: "5px 12px",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "22px",
                    color: "black",
                  }}
                  placeholder="PAN Number"
                  name="pan"
                  onChange={handleChange}
                />
                {errors.pan && (
                  <Typography
                    style={{
                      color: "red",
                      fontSize: "12PX",
                      fontWeight: "400",
                      lineHeight: "22px",
                    }}
                  >
                    {errors.pan}
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  height: "auto",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14PX",
                    fontWeight: "400",
                    lineHeight: "22px",
                  }}
                >
                  Driving License{" "}
                  <span style={{ color: "red", marginLeft: "5px" }}>*</span>
                </Typography>
                <InputBase
                  value={formData.driving_licence}
                  sx={{
                    backgroundColor: "white",
                    width: "100%",
                    height: "32px",
                    borderRadius: "2px",
                    border: "1px solid",
                    borderColor: "secondary.border",
                    padding: "5px 12px",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "22px",
                    color: "black",
                  }}
                  placeholder="Driving License"
                  name="driving_licence"
                  onChange={handleChange}
                />
                {errors.driving_licence && (
                  <Typography
                    style={{
                      color: "red",
                      fontSize: "12PX",
                      fontWeight: "400",
                      lineHeight: "22px",
                    }}
                  >
                    {errors.driving_licence}
                  </Typography>
                )}
              </Box>
            </Box>
           
          </Box>
       
      <Box
  sx={{
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
    alignItems: "center",
    padding: "1rem",
    marginTop : "-4rem",
    
  }}
>
  {docimagePreviews?.map((image, index) => (
    <Box
      key={index}
      sx={{
        width: "104px", // Adjust width to keep images aligned in a grid
        height: "140px",
        border: "1px solid",
        borderColor: "secondary.border",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        top : "1rem",
        cursor: "pointer",
        backgroundColor: "background.upload",
        overflow: "hidden",
        "&:hover .overlay": { display: "block" },
        "&:hover .delete-button": { display: "flex" },
    
      }}
    >
      <img
        src={image}
        alt={`Uploaded ${index}`}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "8px",
          transition: "opacity 0.3s",
        }}
      />
      <Box
        className="overlay"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "#00000080",
          display: "none",
          borderRadius: "8px",
          
        }}
      />
      <Tooltip title="Delete" arrow>
        <IconButton
          className="delete-button"
          onClick={() => handleRemoveImage(index, "document")}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            display: "none",
            transform: "translate(-50%, -50%)",
          }}
        >
          <DeleteOutlineIcon sx={{ color: "white" }} />
        </IconButton>
      </Tooltip>
    </Box>
  ))}

  {/* Full-Width Upload Document Button */}
  <Box
    sx={{
      width: "100%", // Set to full width
      // height: "140px",
      border: "1px solid",
      borderColor: "secondary.border",
      borderRadius: "8px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      cursor: "pointer",
      backgroundColor: "background.upload",
      marginTop: "2.3rem",
      gap : "8px",
      // top : "1rem"
    }}
  >
    <input
      type="file"
      accept="image/*"
      id="upload-input"
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        opacity: 0,
        cursor: "pointer",
        top: 0,
        left: 0,
      }}
      multiple
      onChange={(e) => handleDocImageChange(e)}
      name="document_img"
    />
    <label
      htmlFor="upload-input"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", gap: "4px" }}>
        <FileUploadOutlinedIcon
          fontSize="large"
          sx={{ color: "#00000040" }}
        />
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "22px",
            marginTop: "5px",
            fontFamily: "Poppins",
          }}
        >
          Upload Document
        </Typography>
      </Box>
    </label>
  </Box>

  {/* Error Message */}
  {errors.document_img && (
    <Typography
      style={{
        color: "red",
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "22px",
      }}
    >
      {errors.document_img}
    </Typography>
  )}
</Box>


        
              <Box sx={{ height: "182px", padding: "1rem", marginTop: "-1rem" , display : "flex", flexDirection : "column", gap : "20px"}}>
               <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
              Vechile Information
            </Typography>
             <Box sx={{display : "flex", gap : "20px", marginTop : "-1rem"}}>
     
                <Box sx={{ width: "50%", display: "flex", flexDirection: "column", gap: "10px" }}>
  <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22px" }}>
    Vehicle Type
    <span style={{ color: "red", marginLeft: "5px" }}>*</span>
  </Typography>
  <Dropdown
    value={formData.vehicle_type} // Ensure the field name matches
    onChange={handleChange}
    options={vehicleType}
    name="vehicle_type" // Ensure the field name matches
    optionLabel="name"
    style={{ zIndex: "1000" }}
    placeholder="Select Vehicle"
    className="w-full md:w-14rem"
    checkmark={true}
    highlightOnSelect={false}
  />
  {errors.vehicle_type && (
    <Typography style={{ color: "red", fontSize: "12px", fontWeight: "400", lineHeight: "22px" }}>
      {errors.vehicle_type}
    </Typography>
  )}
</Box>
                   <Box
                    sx={{
                      width: "50%",
                      display: "flex",
                      flexDirection: "column", 
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14PX",
                        fontWeight: "400",
                        lineHeight: "22px",
                      }}
                    >
                      Vehicle Number
                      <span style={{ color: "red", marginLeft: "5px" }}>*</span>
                    </Typography>
                    <InputBase
                      value={formData.vehicle_number}
                      sx={{
                        backgroundColor: "white",
                        width: "100%",
                        height: "32px",
                       borderRadius: "2px",
                        border: "1px solid",
                        borderColor: "secondary.border",
                        padding: "5px 12px",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "22px",
                        color: "black",
                      }}
                      placeholder="Enter Number"
                      name="vehicle_number"
                      onChange={handleChange}
                    />
                    {errors.vehicle_number && (
                      <Typography
                        style={{
                          color: "red",
                          fontSize: "12PX",
                          fontWeight: "400",
                          lineHeight: "22px",
                        }}
                      >
                        {errors.vehicle_number}
                      </Typography>
                    )}
                  </Box>
                  
                  <Box sx={{ width: "50%", display: "flex", flexDirection: "column", gap: "10px" }}>
  <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22px" }}>
    Vehicle Insurance
    <span style={{ color: "red", marginLeft: "5px" }}>*</span>
  </Typography>
  <Dropdown
    value={formData.vehicle_insurance} // Ensure the field name matches
    onChange={handleChange}
    options={vehicleInsurance}
    name="vehicle_insurance" // Ensure the field name matches
    optionLabel="name"
    style={{ zIndex: "1000" }}
    placeholder="Select Insurance"
    className="w-full md:w-14rem"
    checkmark={true}
    highlightOnSelect={false}
  />
  {errors.vehicle_insurance && (
    <Typography style={{ color: "red", fontSize: "12px", fontWeight: "400", lineHeight: "22px" }}>
      {errors.vehicle_insurance}
    </Typography>
  )}
</Box>
             </Box>
      </Box>

              <Box sx={{ height: "182px", padding: "1rem" , display : "flex", flexDirection : "column", gap : "20px", marginTop : "-3rem"}}>
               <Typography sx={{ fontSize: "14px", fontWeight: "semibold" }}>
              Profile Status
            </Typography>
          
             <Box sx={{ display: "flex", gap: "20px", marginTop :"-1rem" }}>
  <Box sx={{ width: "50%", display: "flex", flexDirection: "column", gap: "10px" }}>
    <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22px" }}>
      KYC Status
      <span style={{ color: "red", marginLeft: "5px" }}>*</span>
    </Typography>
    <Dropdown
      value={formData.KYCstatus} // Ensure the field name matches
      onChange={handleChange}
      options={KYCStatus}
      name="KYCstatus" // Ensure the field name matches
      optionLabel="name"
      style={{ zIndex: "1000" }}
      placeholder="Select Status"
      className="w-full md:w-14rem"
      checkmark={true}
      highlightOnSelect={false}
    />
    {errors.KYCstatus && (
      <Typography style={{ color: "red", fontSize: "12px", fontWeight: "400", lineHeight: "22px" }}>
        {errors.KYCstatus}
      </Typography>
    )}
  </Box>
  
  <Box sx={{ width: "50%", display: "flex", flexDirection: "column", gap: "10px" }}>
    <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22px" }}>
      User Status
      <span style={{ color: "red", marginLeft: "5px" }}>*</span>
    </Typography>
    <Dropdown
      value={formData.Userstatus} // Ensure the field name matches
      onChange={handleChange}
      options={UserStatus}
      name="Userstatus" // Ensure the field name matches
      optionLabel="name"
      style={{ zIndex: "1000" }}
      placeholder="Select Status"
      className="w-full md:w-14rem"
      checkmark={true}
      highlightOnSelect={false}
    />
    {errors.Userstatus && (
      <Typography style={{ color: "red", fontSize: "12px", fontWeight: "400", lineHeight: "22px" }}>
        {errors.Userstatus}
      </Typography>
    )}
  </Box>
</Box>
            </Box>
              {/* <Box sx={{ height: "100px", padding: "1rem", marginTop: "-3.5rem" , display : "flex", flexDirection : "row", gap : "20px"}}>
               
                  <Box sx={{ width: "551px", display: "flex", flexDirection: "column", gap: "10px" }}>
                      <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22px" }}>
                        Password
                        <span style={{ color: "red", marginLeft: "5px" }}>*</span>
                      </Typography>
                      <InputBase
                        value={formData.password}  // Reflects the updated password
                        sx={{
                          backgroundColor: "white",
                          width: "full",
                          height: "32px",
                          borderRadius: "2px",
                          border: "1px solid",
                          borderColor: "secondary.border",
                          padding: "5px 12px",
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "22px",
                          color: "black",
                        }}
                        placeholder="Enter Password"
                        name="password"
                        onChange={handleChange}
                      />
                      {errors.password && (
                        <Typography style={{ color: "red", fontSize: "12px", fontWeight: "400", lineHeight: "22px", marginTop : "-0.5rem" }}>
                          {errors.password}
                        </Typography>
                      )}
                    </Box>

                  
                   <Box sx={{ height: "auto", marginTop: "auto",  width : "50%" }}>
                      <Button
                        variant="contained"
                        onClick={handleGeneratePassword}
                        sx={{ textTransform: "none" }}
                      >
                        {edit ? "Reset Password" : "Generate Password"}
                      </Button>

                      {errors.vechile_number && (
                        <Typography
                          style={{
                            color: "red",
                            fontSize: "12px",
                            fontWeight: "400",
                            lineHeight: "22px",
                          }}
                        >
                          {errors.vechile_number}
                        </Typography>
                      )}
                    </Box>

             </Box> */}

          {/* ---------------------------- */}
          <Box
            sx={{
              backgroundColor: "#EEEEEE",
              display: "flex",
              alignItems: "center",
              position: "absolute",
              // bottom: 0,
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Box sx={{ padding: "12px 24px", display: "flex", gap: "20px" }}>
              <Button
                variant="outlined"
                sx={{
                  textTransform: "none",
                  color: "black",
                  backgroundColor: "white",
                  height: "32px",
                  width: "67px",
                }}
                onClick={handleClose}
              >
                {" "}
                cancel
              </Button>
              <Button
                variant="contained"
                sx={{ textTransform: "none", height: "32px", width: "67px" }}
                onClick={() =>
                  edit
                    ? handleUpdateDelivery(deliveryDetails.partner_id)
                    : handleSaveDelivery()
                }
              >
                {" "}
                {edit ? "Update" : "Save"}
              </Button>
            </Box>
          </Box>

        </Box>
    
      </Modal>
    </Box>
  );
}

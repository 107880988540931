import * as React from 'react';
import { Box, Button, Typography, IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import dashboard from "../Assets/dash/Dashboard.png";
import delivery from "../Assets/dash/delivery.svg";
import order from "../Assets/dash/order.svg";
import product from "../Assets/dash/product.svg";
import user from "../Assets/dash/user.svg";
import { useDispatch, useSelector } from "react-redux";
import { updatepageName } from "../Redux/page";
import { Menu as PrimeMenu } from 'primereact/menu';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { useNavigate } from 'react-router-dom';

export function Sidebar() {
  const dispatch = useDispatch();
  const editMenu = React.useRef(null);
  const pagename = useSelector(state => state.page.pageName);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const subMenuOpen = Boolean(subMenuAnchorEl);
  const navigate = useNavigate()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSubMenuAnchorEl(null);
  };

  const handleSubMenuOpen = (event) => {
    setSubMenuAnchorEl(event.currentTarget);
  };

  const handleSubMenuClose = () => {
    setSubMenuAnchorEl(null);
  };

  const editItems = [
    {
      label: 'Product Details',
      command: () => dispatch(updatepageName("product_details"))
    },
    {
      label: 'Inventory',
      command: () => dispatch(updatepageName("inventory"))
    }
  ];
  const logout=()=>{
    localStorage.clear() 
    navigate("/")
}
  return (
    <Box sx={{ display: 'block', position: {xs:"absolute", md:"initial"}, top: {xs:16, md:"initial"}, right: {xs:20, md:"initial"} }}>
      {/* Desktop Sidebar */}
      <Box
        sx={{
          display: { xs: 'none', md: 'flex' },
          width: "84px",
          height: "710px",
          padding: "24px 8px",
          backgroundColor: "background.light",
          marginLeft: "20px",
          marginTop: "20px",
          borderRadius: "16px",
          boxShadow: "0px 0px 25px 0px #0000001A",
          flexDirection: "column",
          gap: "18px",
          position: "sticky",
          top: "24px"
        }}
      >
        <Button sx={{ display: "flex", flexDirection: "column", gap: "2px" }} onClick={() => dispatch(updatepageName("dashboard"))}>
          <Box component="img" src={dashboard} sx={{ color: "green" }} />
          <Typography sx={{ fontSize: "9px", color: pagename === "dashboard" ? "primary.main" : "text.secondary", textAlign: "center" }}>
            Dashboard
          </Typography>
        </Button>

        <Box className="productManagement" sx={{ position: "relative" }}>
          <PrimeMenu model={editItems} popup ref={editMenu} style={{ marginLeft: "78px", marginTop: "-70px" }} className="custom-menu" />
          <Button sx={{ display: "flex", flexDirection: "column", gap: "2px" }} onClick={(event) => editMenu.current.toggle(event)}>
            <Box component="img" src={product} />
            <Typography sx={{ fontSize: "9px", color: pagename === "product_details" || pagename === "inventory" ? "primary.main" : "text.secondary", textAlign: "center" }}>
              Product Management
            </Typography>
          </Button>
        </Box>

        <Button sx={{ display: "flex", flexDirection: "column", gap: "2px" }} onClick={() => dispatch(updatepageName("order_management"))}>
          <Box component="img" src={order} />
          <Typography sx={{ fontSize: "9px", color: pagename === "order_management" ? "primary.main" : "text.secondary", textAlign: "center" }}>
            Order Management
          </Typography>
        </Button>

        <Button sx={{ display: "flex", flexDirection: "column", gap: "2px" }} onClick={() => dispatch(updatepageName("delivery_management"))}>
          <Box component="img" src={delivery} />
          <Typography sx={{ fontSize: "9px", color: pagename === "delivery_management" ? "primary.main" : "text.secondary", textAlign: "center" }}>
            Delivery Management
          </Typography>
        </Button>

        <Button sx={{ display: "flex", flexDirection: "column", gap: "2px" }} onClick={() => dispatch(updatepageName("user_management"))}>
          <Box component="img" src={user} />
          <Typography sx={{ fontSize: "9px", color: pagename === "user_management" ? "primary.main" : "text.secondary", textAlign: "center" }}>
            User Management
          </Typography>
        </Button>

        <Button sx={{ display: "flex", flexDirection: "column", gap: "2px" }} onClick={() =>logout()}>
          <Box component="img" src={user} />
          <Typography sx={{ fontSize: "9px", color: pagename === "user_management" ? "primary.main" : "text.secondary", textAlign: "center" }}>
            Logout
          </Typography>
        </Button>
      </Box>

      {/* Mobile Hamburger Menu */}
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <IconButton
          id="compact-menu-button"
          aria-controls={menuOpen ? 'compact-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? 'true' : undefined}
          onClick={handleClick}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>

        <Menu
          id="compact-menu"
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'compact-menu-button',
          }}
        >
          <Box >
            {/* <Typography variant="h6" sx={{ mb: 1 }}>Menu</Typography>onClick={() => dispatch(updatepageName("dashboard")) */}
            <MenuItem onClick={() => { dispatch(updatepageName("dashboard")); handleClose(); }}>Dashboard</MenuItem>
            {/* Product Management with Submenu */}
            <MenuItem
                onClick={handleSubMenuOpen}
                aria-haspopup="true"
                aria-controls={subMenuOpen ? 'product-submenu' : undefined}
            >
                Product Management
            </MenuItem>

            {/* Submenu for Product Management */}
            <Menu
                id="product-submenu"
                anchorEl={subMenuAnchorEl}
                open={subMenuOpen}
                onClose={handleSubMenuClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                <MenuItem onClick={() => { dispatch(updatepageName("product_details")); handleClose(); }}>Product Details</MenuItem>
                <MenuItem onClick={() => { dispatch(updatepageName("inventory")); handleClose(); }}>Inventory</MenuItem>
            </Menu>
            <MenuItem onClick={() => { dispatch(updatepageName("order_management")); handleClose(); }}>Order Management</MenuItem>
            <MenuItem onClick={() => { dispatch(updatepageName("delivery_management")); handleClose(); }}>Delivery Management</MenuItem>
            <MenuItem onClick={() => { dispatch(updatepageName("user_management")); handleClose(); }}>User Management</MenuItem>
            <MenuItem onClick={() =>logout()}>Logout</MenuItem>
          </Box>
        </Menu>
      </Box>
    </Box>
  );
}
